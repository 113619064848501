import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import router from './router'; //Importa el objeto router

import 'vuetify/dist/vuetify.css';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import './assets/style/app.css';  // Asegúrate de ajustar la ruta según tu estructura de carpetas


Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(Vuetify);

const vuetify = new Vuetify();

new Vue({
  render: h => h(App),
  router,
  vuetify
}).$mount('#app');
