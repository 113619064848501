<template>
    <v-card class="mx-auto" max-width="400">
      <v-card-title class="headline">
        Observar Usuario
      </v-card-title>
  
      <v-card-text>
        <usuario-form ref="UsuarioForm" :initialData="usuarioData" :disableFields="true" :equipos="equipos"></usuario-form>
      </v-card-text>
  
      <v-card-actions>
        <v-btn @click="redirigirAActualizar" color="primary">
          Actualizar
        </v-btn>
        <v-btn @click="redirigirAUsuarios" color="primary">
          Volver
        </v-btn>
      </v-card-actions>
      <v-alert
      v-if="alertMessage"
      dense
      :type="alertType"
    >
      {{ alertMessage }}
    </v-alert>
    </v-card>
  </template>
  
  <script>
  import UsuarioForm from '../../components/UsuarioComponents/UsuarioForm.vue';
  
  export default {
    components: {
      UsuarioForm,
    },
    data() {
      return {
        alertMessage: null,
        alertType: null,
        usuarioData: null,
        equipos: [], // Inicializa equipos como un arreglo vacío
      };
    },
    methods: {
      async cargarDatosUsuario(id) {
        try {
          const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

          const response = await fetch(`${API_BASE_URL}/usuarios/${id}`);
          if (!response.ok) {
            throw new Error('Error al obtener los datos del usuario');
          }
          const userData = await response.json();
          this.usuarioData = userData;
        } catch (error) {
          console.error('Error al cargar los datos del usuario:', error);
          this.setAlert('Error al cargar los datos del usuario, pulse en volver e intentelo de nuevo.', 'error');
        }
      },
      redirigirAActualizar() {
        // Redirige a la página de actualizar con el mismo ID de usuario
        this.$router.push({ name: 'ActualizarUsuario', params: { id: this.usuarioData.id } });
      },
      redirigirAUsuarios() {

        const id = localStorage.getItem('userId')

        if (id == 0 || id == '0') {

          // Redirige a la página de usuarios
          this.$router.push('/usuarios');

        }
      },
      setAlert(message, type) {
        this.alertMessage = message;
        this.alertType = type;
      },
      async cargarEquipos() {
        try {
          const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

          const response = await fetch(`${API_BASE_URL}/equipos`);
          if (!response.ok) {
            throw new Error('No se pudo obtener la lista de equipos');
          }
          const data = await response.json();
          //this.equipos = data; // Asigna los datos de los equipos a la propiedad 'equipos'
          this.equipos = data.map(equipo => ({
          ...equipo,
          nombreCompuesto: `${equipo.nombre} > ${equipo.club}`
        }));

        } catch (error) {
          console.error('Error al cargar los equipos:', error);
          this.setAlert('Error al cargar los equipos. Por favor, intenta nuevamente.', 'error');
        }
      },

    },
    created() {
      // Obtén el ID del usuario de los parámetros de la ruta
      const usuarioId = this.$route.params.id;
      // Llama a cargarEquipos para obtener la lista de equipos
      this.cargarEquipos();
      // Carga los datos del usuario al iniciar la página
      this.cargarDatosUsuario(usuarioId);
      
    },
    
  };
  </script>
  
  <style scoped>
  /* Puedes agregar estilos específicos para tu componente aquí */
  </style>
  