<template>
    <v-card class="mx-auto" max-width="400">
      <v-card-title class="headline">
        Registro de un nuevo equipo
      </v-card-title>
  
      <v-card-text>
        <equipo-form ref="EquipoForm"></equipo-form>
      </v-card-text>
  
      <v-card-actions>
        <v-btn @click="submitForm" color="primary">
          Registrar
        </v-btn>
        <v-btn @click="volver" color="red">
          Volver
        </v-btn>
      </v-card-actions>
  
      <v-alert
        v-if="alertMessage"
        dense
        :type="alertType"
      >
        {{ alertMessage }}
      </v-alert>
  
      <v-dialog v-model="dialog" max-width="800">
        <v-card>
          <v-card-title class="headline">Equipo Registrado</v-card-title>
          <v-card-text>
            <div>
              <p><strong>Nombre:</strong> {{ equipo.nombre }}</p>
              <p><strong>Categoría:</strong> {{ equipo.categoria }}</p>
              <p><strong>Club:</strong> {{ equipo.club }}</p>
              <p><strong>Código del Equipo:</strong> {{ equipo.codigo }}</p>
              <p>Memorice este código para futuras referencias.</p>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" text @click="cerrarDialog">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </template>
  
  <script>
  import EquipoForm from '../../components/EquipoComponents/EquipoForm.vue';
  
  export default {
    components: {
      EquipoForm,
    },
    data() {
      return {
        alertMessage: null,
        alertType: null,
        dialog: false,
        equipo: {
          nombre: '',
          categoria: '',
          club: '',
          codigo: ''
        },
        isUserLoggedIn: false,
      };
    },
    methods: {

      volver(){
        this.$router.push({ name: 'Register'  });
      },

      cerrarDialog() {
        this.dialog = false;
        this.$refs.EquipoForm.resetForm();

        // Lógica para verificar si el usuario ha iniciado sesión (por ejemplo, comprobando localStorage)
        const usernameLogedIn = localStorage.getItem('usernameLogedIn');
        this.isUserLoggedIn = !!usernameLogedIn; // Actualiza el estado según si hay un usuario logueado o no

        if(!this.isUserLoggedIn){ // si el usuario no esta logueado es porque lo esta registrando
          this.$router.push({ name: 'Register'  });
        }else{ // si el usuario esta logueado
          this.$router.push({ name: 'Equipos'  });
        }
      
      },

      async submitForm() {
        // Accede a los datos del formulario mediante el ref y el método del componente hijo
        const equipoFormRef = this.$refs.EquipoForm;
        const equipoFormData = equipoFormRef.getFormData();
        equipoFormRef.activateRules();
  
        // Validación de campos vacíos
        if (!equipoFormData.nombre || !equipoFormData.categoria || !equipoFormData.club) {
          this.setAlert('Hay campos incompletos. Por favor, rellene todos los campos.', 'error');
          return;
        }
  
        try {
          // Generar un número aleatorio de 6 cifras
          const codigo = Math.floor(100000 + Math.random() * 900000);
  
          // Convertir los valores a mayúsculas
          const nombreMayuscula = equipoFormData.nombre.toUpperCase();
          const categoriaMayuscula = equipoFormData.categoria.toUpperCase();
          const clubMayuscula = equipoFormData.club.toUpperCase();
          const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

          // Intenta realizar la solicitud POST con los datos del formulario
          const response = await fetch(`${API_BASE_URL}/equipos`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              codigo: codigo,
              nombre: nombreMayuscula,
              categoria: categoriaMayuscula,
              club: clubMayuscula,
            }),
          });
  
          if (!response.ok) {
            let errorMessage = 'Error al registrar el equipo, intentelo de nuevo.';
  
            try {
              const responseText = await response.text();
              errorMessage = responseText || errorMessage;
            } catch (textError) {
              console.error('Error al obtener el texto de la respuesta:', textError);
            }
  
            this.setAlert(errorMessage, 'error');
            return;
          }
  
          // Mostrar diálogo de éxito
          this.equipo = {
            codigo: codigo,
            nombre: nombreMayuscula,
            categoria: categoriaMayuscula,
            club: clubMayuscula,
          };
          this.dialog = true;
  
        } catch (error) {
          console.error('Error al realizar la solicitud:', error);
          this.setAlert('Ocurrió un error. Por favor, inténtelo de nuevo.', 'error');
        }
      },
      setAlert(message, type) {
        this.alertMessage = message;
        this.alertType = type;
      },
    },
  };
  </script>
  
  <style scoped>
  /* Puedes agregar estilos específicos para tu componente aquí */
  </style>
  