<template>
    <div>
      <v-text-field
        v-model="formData.nombre"
        label="Nombre"
        :rules="nombreRules"
        append-icon="*"
        :disabled="disableFields"
      ></v-text-field>
  
      
    </div>
  </template>
  
  <script>
  export default {
    props: {
      initialData: {
        type: Object,
        default: null,
      },
      disableFields: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        formData: {
          nombre: '',
        },
        // Agrega una propiedad para controlar la visibilidad de las reglas
        showRules: false,
      };
    },
    computed: {
      nombreRules() {
        return [
          (v) => (!!v || this.showRules) || 'El nombre es obligatorio',
          // Otras reglas de validación para el nombre
        ];
      },
    },
    methods: {
      // Método para activar manualmente las reglas
      activateRules() {
        this.showRules = true;
      },
      // Método para obtener los datos del formulario
      getFormData() {
        return { ...this.formData };
      },
      // Método para resetear el formulario
      resetForm() {
        // Desactiva las reglas de validación
        this.showRules = false;
  
        // Restablece los valores de los campos a sus estados iniciales
        this.formData = {
          nombre: '',
        };
      },
    },
    watch: {
      initialData: {
        immediate: true,
        handler(newData) {
          if (newData) {
            this.formData = {
              nombre: newData.nombre,
            };
          }
        },
      },
    },
  };
  </script>
  
  <style scoped>
  /* Puedes agregar estilos específicos para tu componente aquí */
  </style>
  