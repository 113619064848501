<template>
    <v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-card-title class="headline">
          Confirmar Acción
        </v-card-title>
  
        <v-card-text>
          {{ mensajeConfirmacion }}
        </v-card-text>
  
        <v-card-actions>
          <v-btn @click="confirmarAccion" color="error" dark>
            Confirmar
          </v-btn>
          <v-btn @click="cerrarDialog" color="primary" dark>
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    props: {
      showDialog: {
        type: Boolean,
        required: true,
      },
      closeDialog: {
        type: Function,
        required: true,
      },
      confirmarAccionUsuario: {
        type: Function,
        required: true,
      },
      mensajeConfirmacion: {
        type: String,
        default: '',
      },
    },
    computed: {
      dialog: {
        get() {
          return this.showDialog;
        },
        set(value) {
          if (!value) {
            // Cerrar el diálogo cuando se establece en falso
            this.closeDialog();
          }
        },
      },
    },
    methods: {
      confirmarAccion() {
        this.confirmarAccionUsuario();
        this.cerrarDialog();
      },
      cerrarDialog() {
        this.closeDialog();
      },
    },
  };
  </script>
  