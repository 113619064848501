<template>
    <v-container>
        <v-alert v-if="showAlert" dismissible type="warning" closable="true" icon="mdi-alert"  class="text-h6">
            <template v-slot:title>
                Aviso Importante
            </template>
            <span>
                Los rivales que han participado ya en partidos no pueden ser eliminados.
            </span>
        </v-alert>
        <v-alert ref="alert" :value="true" v-model="alert.show" :type="alert.type">
            {{ alert.message }}
        </v-alert>
        <v-card-title class="headline">
            Listado de Rivales de la liga
            <v-spacer></v-spacer> <!-- Agrega un espacio flexible -->
            <v-btn @click="redirigirACrearRival" icon>
                <v-icon>fas fa-plus</v-icon> <!-- Ajusta el ícono según tu necesidad -->
            </v-btn>
        </v-card-title>
        <v-data-table :headers="headers" :items="rivales" class="elevation-1">
            <template v-slot:item="{ item }">
                <tr>
                    <td>
                        <v-btn disabled @click="redirigirAInformeRival(item.id)" icon>
                            <v-icon>fas fa-eye</v-icon>
                        </v-btn>
                        <v-btn @click="mostrarConfirmacionEliminar(item.id)" icon>
                            <v-icon>fas fa-trash-alt</v-icon>
                        </v-btn>
                    </td>
                    <td>{{ item.nombre }}</td>
                </tr>
            </template>
        </v-data-table>
        <!-- Modal de Confirmación para Eliminar -->



        <confirmacion-eliminar-rival :showDialog="confirmacionEliminar" :closeDialog="cerrarConfirmacionEliminar"
            :confirmarAccionUsuario="eliminarjugador" :mensajeConfirmacion="mensajeConfirmacion" />




    </v-container>
</template>
  
  
  
<script>

import ConfirmacionEliminarRival from '../../components/ConfirmacionEliminar.vue';


export default {
    components: {
        ConfirmacionEliminarRival,
    },
    data() {
        return {
            showAlert: true,
            headers: [
                { text: 'Acciones', value: 'acciones', sortable: false },
                { text: 'Nombre', value: 'nombre' },
            ],
            rivales: [],
            codigoUsuarioFiltro: '',
            confirmacionEliminar: false,
            rivalAEliminarId: null,
            mensajeConfirmacion: '',
            alert: {
                show: false,
                type: 'success', // Puedes cambiar esto dinámicamente según el resultado
                message: '',
            },
        };
    },
    created() {
        // Obtén el mensaje de éxito de los parámetros de la ruta
        const successMessage = this.$route.params.successMessage;

        // Si hay un mensaje de éxito, muéstralo usando la función de alerta
        if (successMessage) {
            this.success(successMessage);
        }
    },
    methods: {
        success(message) {
            this.alert.show = true;
            this.alert.type = 'success';
            this.alert.message = message;
        },
        error(message) {
            this.alert.show = true;
            this.alert.type = 'error';
            this.alert.message = message;
        },
        
        redirigirAInformeRival(id) {
            //TODO Cuando se haga informes del rival este llevará a ver el informe
        },
        mostrarAlerta(accion, id) {
            alert(`Botón ${accion} clickeado para el rival con ID ${id}`);
        },
        mostrarConfirmacionEliminar(id) {
            this.rivalAEliminarId = id;
            this.mensajeConfirmacion = `¿Estás seguro de que deseas eliminar el rival con ID ${id}?`;
            this.confirmacionEliminar = true;
        },
        cerrarConfirmacionEliminar() {
            this.rivalAEliminarId = null;
            this.mensajeConfirmacionEliminar = '';
            this.confirmacionEliminar = false;
        },
        eliminarjugador() {
            
            const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

            // Almacena el ID antes de realizar la petición
            const idAEliminar = this.rivalAEliminarId;
            // Realizar la petición DELETE a la API para eliminar el rival
            fetch(`${API_BASE_URL}/rivales/borrar/${this.rivalAEliminarId}`, {
                method: 'DELETE',
            })
                .then(response => {
                    if (response.ok) {
                        // rival eliminado correctamente
                        this.success(`Rival con ID ${idAEliminar} eliminado correctamente.`);
                    } else {
                        // Ocurrió un error al eliminar el rival
                        this.error(`Error al eliminar el rival con ID ${idAEliminar}, este ya debió de haber sido eliminado previamente o aparece en un partido registrado.`);
                    }
                })
                .catch(error => {
                    console.error('Error al realizar la solicitud DELETE:', error);
                    // Mostrar mensaje de error en caso de fallo en la solicitud
                    this.error('Error al realizar la solicitud DELETE. Por favor, intenta nuevamente.');
                })
                .finally(() => {
                    // Cerrar el modal después de eliminar o en caso de error
                    this.cerrarConfirmacionEliminar();
                    // Recargar la lista de rivales después de eliminar
                    this.cargarRivales();
                });
        },
        cargarRivales() {
            const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

            // Realizar la petición GET a la API de rivales
            fetch(`${API_BASE_URL}/rivales`)
                .then(response => response.json())
                .then(data => {
                    // Asignar los datos a la propiedad 'rivales'
                    this.rivales = data.filter(rival => rival.codigo === this.codigoUsuarioFiltro);
                })
                .catch(error => {
                    console.error('Error al cargar los datos:', error);
                });
        },
        redirigirACrearRival() {
            // Redirige a la página de creación de usuario
            this.$router.push({ name: 'CrearRival' });
        },

        async getCodigoUsuario() {
            const usuarioId = localStorage.getItem('userId');
            const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

            const responseCodigo = await fetch(`${API_BASE_URL}/usuarios/${usuarioId}/codigo`);
            if (!responseCodigo.ok) {
                throw new Error('No se pudo obtener el código de usuario');
            }
            const codigoUsuario = await responseCodigo.text(); // Suponiendo que el código de usuario es un texto simple

            this.codigoUsuarioFiltro = codigoUsuario;
        },
        async inicializar() {
            try {
                await this.getCodigoUsuario(); // Esperar a que getCodigoUsuario termine
                this.cargarRivales(); // Ejecutar cargarInformes después de obtener el código de usuario
            } catch (error) {
                console.error('Error al inicializar:', error);
                this.error('Error al inicializar la carga de Rivales.');
            }
        },
    },
    mounted() {
        this.inicializar();
    },
};
</script>
  
<style scoped>
/* Estilos específicos del componente */
</style>
  