<template>
  <v-card class="mx-auto" max-width="2000">
    <v-card-title class="headline">
      Crear Nuevo Informe
    </v-card-title>
    <v-card-text>
      <informe-form ref="InformeForm"></informe-form>
    </v-card-text>
    <v-card-actions>
      <v-btn @click="submitForm" color="primary">
        Crear
      </v-btn>
    </v-card-actions>
    <v-alert v-if="alertMessage" dense :type="alertType">
      {{ alertMessage }}
    </v-alert>
  </v-card>
</template>

<script>
import InformeForm from '../../../components/InformeComponents/InformeForm.vue';

export default {
  components: {
    InformeForm,
  },
  data() {
    return {
      alertMessage: null,
      alertType: null,
    };
  },
  methods: {
    async submitForm() {

      const informeFormRef = this.$refs.InformeForm;
      const informeFormData = informeFormRef.getFormData();

      if (!informeFormData.nombre || !informeFormData.descripcion) {
        this.setAlert('Hay campos incompletos. Por favor, rellene todos los campos.', 'error');
        return;
      }

      try {
        const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
        const usuarioId = localStorage.getItem('userId');
        const responseCodigo = await fetch(`${API_BASE_URL}/usuarios/${usuarioId}/codigo`);
        if (!responseCodigo.ok) {
          throw new Error('No se pudo obtener el código de usuario');
        }
        const codigoUsuario = await responseCodigo.text(); // Suponiendo que el código de usuario es un texto simple
        // Agrega el código de usuario a informeFormData
        informeFormData.codigo = codigoUsuario;

        const response = await fetch(`${API_BASE_URL}/informes`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(informeFormData),
        });

        if (!response.ok) {
          const errorMessage = await response.text();
          this.setAlert(errorMessage || 'Error al crear el informe.', 'error');
          return;
        }

        this.setAlert('Informe creado exitosamente.', 'success');
        this.$router.push({ name: 'Informes' });
      } catch (error) {
        console.error('Error al realizar la solicitud:', error);
        this.setAlert('Ocurrió un error. Por favor, inténtelo de nuevo.', 'error');
      }
    },
    setAlert(message, type) {
      this.alertMessage = message;
      this.alertType = type;
    },
  },
};
</script>

<style scoped>
/* Estilos específicos del componente */
</style>