<template>
    <v-container>
        <v-alert ref="alert" :value="true" v-model="alert.show" :type="alert.type">
            {{ alert.message }}
        </v-alert>

        <v-card-title class="headline">
            Listado de Partidos Registrados
            <v-spacer></v-spacer>
        </v-card-title>

        <!-- Buscador -->
        <v-text-field
            v-model="rivalFiltro"
            label="Buscar por Rival"
            prepend-icon="mdi-magnify"
            class="mb-4"
        ></v-text-field>

        <v-data-table :headers="headers" :items="filteredPartidos" class="elevation-1">
            <template v-slot:item="{ item }">
                <tr>
                    <td><strong>{{ item.jornada }}</strong></td>
                    <td>{{ item.lugar }}</td>
                    <td>{{ item.rival }}</td>
                    <td>{{ item.resultado }}</td>
                    <td :style="{ backgroundColor: getColorByResult(item.result) }">{{ item.result }}</td>
                </tr>
            </template>
        </v-data-table>

        <!-- Modal de Confirmación para Eliminar -->
        <confirmacion-eliminar-partido
            :showDialog="confirmacionEliminar"
            :closeDialog="cerrarConfirmacionEliminar"
            :confirmarAccionUsuario="eliminarPartido"
            :mensajeConfirmacion="mensajeConfirmacion"
        />
    </v-container>
</template>

<script>
import ConfirmacionEliminarPartido from '../../components/ConfirmacionEliminar.vue';

export default {
    components: {
        ConfirmacionEliminarPartido,
    },
    data() {
        return {
            headers: [
                { text: 'Jornada', value: 'jornada' },
                { text: 'Lugar', value: 'lugar' },
                { text: 'Rival', value: 'rival' },
                { text: 'Marcador', value: 'resultado' },
                { text: 'Resultado', value: 'result' }
            ],
            partidos: [],
            codigoUsuarioFiltro: '',
            rivalFiltro: '', // Nueva variable para el filtro
            confirmacionEliminar: false,
            partidoAEliminarId: null,
            mensajeConfirmacion: '',
            alert: {
                show: false,
                type: 'success',
                message: '',
            },
        };
    },
    created() {
        const successMessage = this.$route.params.successMessage;
        if (successMessage) {
            this.success(successMessage);
        }
    },
    computed: {
        filteredPartidos() {
            return this.partidos.filter(partido => 
                partido.rival.toLowerCase().includes(this.rivalFiltro.toLowerCase())
            );
        }
    },
    methods: {
        getColorByResult(result) {
            if (result === 'Victoria') {
                return '#C8E6C9'; // verde claro
            } else if (result === 'Empate') {
                return '#BBDEFB'; // azul claro
            } else if (result === 'Derrota') {
                return '#FFCDD2'; // rojo claro
            } else {
                return ''; // Sin color de fondo por defecto
            }
        },

        success(message) {
            this.alert.show = true;
            this.alert.type = 'success';
            this.alert.message = message;
        },
        error(message) {
            this.alert.show = true;
            this.alert.type = 'error';
            this.alert.message = message;
        },
        mostrarConfirmacionEliminar(id) {
            this.partidoAEliminarId = id;
            this.mensajeConfirmacion = `¿Estás seguro de que deseas eliminar el partido con ID ${id}?`;
            this.confirmacionEliminar = true;
        },
        cerrarConfirmacionEliminar() {
            this.partidoAEliminarId = null;
            this.mensajeConfirmacion = '';
            this.confirmacionEliminar = false;
        },
        eliminarPartido() {
            // Almacena el ID antes de realizar la petición
            alert("Función todavía no disponible, espera siguiente actualización");
            /*const idAEliminar = this.partidoAEliminarId;

            const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

            // Realizar la petición DELETE a la API para eliminar el usuario
            fetch(`${API_BASE_URL}/partidos/${idAEliminar}`, {
                method: 'DELETE',
            })
                .then(response => {
                    if (response.ok) {
                        // Usuario eliminado correctamente
                        this.success(`Partido con ID ${idAEliminar} eliminado correctamente.`);
                    } else {
                        // Ocurrió un error al eliminar el usuario
                        this.error(`Error al eliminar el partido con ID ${idAEliminar}, este ya debió de haber sido eliminado previamente.`);
                    }
                })
                .catch(error => {
                    console.error('Error al realizar la solicitud DELETE:', error);
                    // Mostrar mensaje de error en caso de fallo en la solicitud
                    this.error('Error al realizar la solicitud DELETE. Por favor, intenta nuevamente.');
                })
                .finally(() => {
                    // Cerrar el modal después de eliminar o en caso de error
                    this.cerrarConfirmacionEliminar();
                    // Recargar la lista de usuarios después de eliminar
                    this.cargarPartidos();
                });*/
        },

        async getCodigoUsuario() {
            const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

            const usuarioId = localStorage.getItem('userId');

            const responseCodigo = await fetch(`${API_BASE_URL}/usuarios/${usuarioId}/codigo`);
            if (!responseCodigo.ok) {
                throw new Error('No se pudo obtener el código de usuario');
            }
            const codigoUsuario = await responseCodigo.text(); // Suponiendo que el código de usuario es un texto simple

            this.codigoUsuarioFiltro = codigoUsuario;
        },

        async cargarPartidos() {
            const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
            const response = await fetch(`${API_BASE_URL}/inicioMenu/partidos/${this.codigoUsuarioFiltro}`);
            if (!response.ok) {
                throw new Error('No se pudo obtener la lista de partidos');
            }
            const data = await response.json();
            this.partidos = data;
        }
    },
    async mounted() {
        await this.getCodigoUsuario();
        await this.cargarPartidos();
    },
};
</script>

<style scoped>
/* Estilos específicos del componente */
</style>
