<template>
  <v-container>
    <v-alert ref="alert" :value="true" v-model="alert.show" :type="alert.type">
      {{ alert.message }}
    </v-alert>
    <v-card-title class="headline">
            Listado de Equipos de la app
            <v-spacer></v-spacer> <!-- Agrega un espacio flexible -->
            <v-btn @click="redirigirACrearEquipo" icon>
                <v-icon>fas fa-plus</v-icon> <!-- Ajusta el ícono según tu necesidad -->
            </v-btn>
        </v-card-title>
    <v-data-table :headers="headers" :items="equipos" class="elevation-1">
      <template v-slot:item="{ item }">
        <tr>
          <td>
            <v-btn @click="redirigirAActualizar(item.codigo)" icon>
              <v-icon>fas fa-pencil-alt</v-icon>
            </v-btn>
            <v-btn @click="redirigirAObservar(item.codigo)" icon>
              <v-icon>fas fa-eye</v-icon>
            </v-btn>
            <v-btn @click="mostrarConfirmacionEliminar(item.codigo)" icon>
              <v-icon>fas fa-trash-alt</v-icon>
            </v-btn>
          </td>
          <td>{{ item.codigo }}</td>
          <td>{{ item.nombre }}</td>
          <td>{{ item.categoria }}</td>
          <td>{{ item.club }}</td>
        </tr>
      </template>
    </v-data-table>
    <!-- Modal de Confirmación para Eliminar -->
    <confirmacion-eliminar-equipo
      :showDialog="confirmacionEliminar"
      :closeDialog="cerrarConfirmacionEliminar"
      :confirmarAccionUsuario="eliminarEquipo"
      :mensajeConfirmacion="mensajeConfirmacion"
    />
  </v-container>
</template>

<script>
import ConfirmacionEliminarEquipo from '../../components/ConfirmacionEliminar.vue';

export default {
  components: {
    ConfirmacionEliminarEquipo,
  },
  data() {
    return {
      headers: [
        { text: 'Acciones', value: 'acciones', sortable: false },
        { text: 'Código', value: 'codigo' },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Categoría', value: 'categoria' },
        { text: 'Club', value: 'club' },
      ],
      equipos: [],
      confirmacionEliminar: false,
      equipoAEliminarCodigo: null,
      mensajeConfirmacion: '',
      alert: {
        show: false,
        type: 'success', // Puedes cambiar esto dinámicamente según el resultado
        message: '',
      },
    };
  },
  methods: {
    success(message) {
      this.alert.show = true;
      this.alert.type = 'success';
      this.alert.message = message;
    },
    error(message) {
      this.alert.show = true;
      this.alert.type = 'error';
      this.alert.message = message;
    },
    redirigirAActualizar(codigo) {
      // Redirige a la página de actualización con el código del equipo seleccionado
      this.$router.push({ name: 'ActualizarEquipo', params: { codigo } });
    },
    redirigirAObservar(codigo) {
      // Redirige a la página de observación con el código del equipo seleccionado
      this.$router.push({ name: 'ObservarEquipo', params: { codigo } });
    },
    mostrarConfirmacionEliminar(codigo) {
      this.equipoAEliminarCodigo = codigo;
      this.mensajeConfirmacion = `¿Estás seguro de que deseas eliminar el equipo con código ${codigo}?`;
      this.confirmacionEliminar = true;
    },
    cerrarConfirmacionEliminar() {
      this.equipoAEliminarCodigo = null;
      this.mensajeConfirmacion = '';
      this.confirmacionEliminar = false;
    },
    eliminarEquipo() {
      // Almacena el código antes de realizar la petición
      const codigoAEliminar = this.equipoAEliminarCodigo;
      const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
      // Realizar la petición DELETE a la API para eliminar el equipo
      fetch(`${API_BASE_URL}/equipos/${codigoAEliminar}`, {
        method: 'DELETE',
      })
        .then(response => {
          if (response.ok) {
            // Equipo eliminado correctamente
            this.success(`Equipo con código ${codigoAEliminar} eliminado correctamente.`);
          } else {
            // Ocurrió un error al eliminar el equipo
            this.error(`Error al eliminar el equipo con código ${codigoAEliminar}, este ya debió de haber sido eliminado previamente.`);
          }
        })
        .catch(error => {
          console.error('Error al realizar la solicitud DELETE:', error);
          // Mostrar mensaje de error en caso de fallo en la solicitud
          this.error('Error al realizar la solicitud DELETE. Por favor, intenta nuevamente.');
        })
      .finally(() => {
        // Cerrar el modal después de eliminar o en caso de error
        this.cerrarConfirmacionEliminar();
        // Recargar la lista de equipos después de eliminar
        this.cargarEquipos();
      });
    },
    cargarEquipos() {
      // Realizar la petición GET a la API de equipos
      const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
      fetch(`${API_BASE_URL}/equipos`)
        .then(response => response.json())
        .then(data => {
          // Asignar los datos a la propiedad 'equipos'
          this.equipos = data;
        })
        .catch(error => {
          console.error('Error al cargar los datos:', error);
          this.error('Error al cargar los datos. Por favor, intenta nuevamente.');
        });
    },
    redirigirACrearEquipo() {
            // Redirige a la página de creación de equipo
            this.$router.push({ name: 'CrearEquipo' });
        },
  },
  mounted() {
    this.cargarEquipos();
  },
};
</script>

<style scoped>
/* Estilos específicos del componente */
</style>
