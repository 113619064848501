import { render, staticRenderFns } from "./ListadoInformes.vue?vue&type=template&id=a2dae734&scoped=true"
import script from "./ListadoInformes.vue?vue&type=script&lang=js"
export * from "./ListadoInformes.vue?vue&type=script&lang=js"
import style0 from "./ListadoInformes.vue?vue&type=style&index=0&id=a2dae734&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2dae734",
  null
  
)

export default component.exports