<template>
    <v-card class="mx-auto" max-width="400">
      <v-card-title class="headline">
        Registro de un nuevo jugador
      </v-card-title>
  
      <v-card-text>
        <jugador-form ref="JugadorForm"></jugador-form>
      </v-card-text>
  
      <v-card-actions>
        <v-btn @click="submitForm" color="primary">
          Registrar
        </v-btn>
      </v-card-actions>
  
      <v-alert
        v-if="alertMessage"
        dense
        :type="alertType"
      >
        {{ alertMessage }}
      </v-alert>
    </v-card>
  </template>
  
  <script>
  import JugadorForm from '../../components/JugadorComponents/JugadorForm.vue';
  
  export default {
    components: {
        JugadorForm,
    },
    data() {
      return {
        alertMessage: null,
        alertType: null,
      };
    },
    methods: {
      async submitForm() {
        // Accede a los datos del formulario mediante el ref y el método del componente hijo
        const jugadorFormRef = this.$refs.JugadorForm;
        const jugadorFormData = jugadorFormRef.getFormData();
        jugadorFormRef.activateRules();
  
        // Validación de campos vacíos
        if (!jugadorFormData.nombre || !jugadorFormData.apellido || !jugadorFormData.apodo ) {
          this.setAlert('Hay campos incompletos. Por favor, rellene todos los campos.', 'error');
          return;
        }
  
        try {

          const usuarioId = localStorage.getItem('userId');
          const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
            const responseCodigo = await fetch(`${API_BASE_URL}/usuarios/${usuarioId}/codigo`);
            if (!responseCodigo.ok) {
                throw new Error('No se pudo obtener el código de usuario');
            }
          const codigoUsuario = await responseCodigo.text(); // Suponiendo que el código de usuario es un texto simple

          // Intenta realizar la solicitud POST con los datos del formulario
          const response = await fetch(`${API_BASE_URL}/jugadores/crear`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              nombre: jugadorFormData.nombre,
              apellido: jugadorFormData.apellido,
              apodo: jugadorFormData.apodo,
              codigo: codigoUsuario,
            }),
          });
  
          if (!response.ok) {
            let errorMessage = 'Error al registrar el jugador.';
  
            try {
              const responseText = await response.text();
              errorMessage = responseText || errorMessage;
            } catch (textError) {
              console.error('Error al obtener el texto de la respuesta:', textError);
            }
  
            this.setAlert(errorMessage, 'error');
            return;
          }

          //Redirijo a ListarAllJugadores.vue y ya se verá el nuevo añadido en la tabla.
          const successMessage = `Jugador "${jugadorFormData.apodo}" registrado exitosamente.`;
          this.$router.push({ name: 'Plantilla', params: { successMessage } });

          //this.setAlert(successMessage, 'success');
          //this.setAlert('Usuario registrado exitosamente.', 'success');
          // Después del registro exitoso, resetea el formulario
          //jugadorFormRef.resetForm();
  
        } catch (error) {
          console.error('Error al realizar la solicitud:', error);
          this.setAlert('Ocurrió un error. Por favor, inténtelo de nuevo.', 'error');
        }
      },
      setAlert(message, type) {
        this.alertMessage = message;
        this.alertType = type;
      },
    },
  };
  </script>
  
  <style scoped>
  /* Puedes agregar estilos específicos para tu componente aquí */
  </style>
  