<template>
    <div>
      <!-- primera fila con 2 columnas -->
      <!-- Barra de botones para controlar las gráficas ult5,ult10, todos-->
      <v-row class="mb-4">
        <v-col cols="12">
          <v-bottom-navigation color="primary">
            <v-btn @click="cambiarVisual(5)">
              <span>Mostrar Ult 5</span>
              <v-icon>mdi-format-list-numbered</v-icon>
            </v-btn>
            <v-btn @click="cambiarVisual(10)">
              <span>Mostrar Ult 10</span>
              <v-icon>mdi-format-list-numbered</v-icon>
            </v-btn>
            <v-btn @click="cambiarVisual('all')">
              <span>Mostrar Todos</span>
              <v-icon>mdi-format-list-bulleted</v-icon>
            </v-btn>
          </v-bottom-navigation>
        </v-col>
      </v-row>
      <v-row class="mb-4">
        <!-- Columna 1: Sparkline Goles a Favor -->
        <v-col cols="6">
          <v-card class="mx-auto text-center" color="green" dark>
            <v-card-text>
              <v-sheet color="rgba(0, 0, 0, .12)">
                <v-sparkline :value="golesFavorGrafica" color="white" height="80" padding="24" stroke-linecap="round"
                  smooth>
                  <template v-slot:label="item">
                    {{ item.value }}
                  </template>
                </v-sparkline>
              </v-sheet>
            </v-card-text>
            <v-card-text>
              <div class="text-h4 font-weight-thin">
                {{tituloAfavorUltimos}}
              </div>
            </v-card-text>
          </v-card>
        </v-col>
  
        <!-- Columna 2: Sparkline Goles en Contra -->
        <v-col cols="6">
          <v-card class="mx-auto text-center" color="red" dark>
            <v-card-text>
              <v-sheet color="rgba(0, 0, 0, .12)">
                <v-sparkline :value="golesEnContraGrafica" color="white" height="80" padding="24" stroke-linecap="round"
                  smooth>
                  <template v-slot:label="item">
                    {{ item.value }}
                  </template>
                </v-sparkline>
              </v-sheet>
            </v-card-text>
            <v-card-text>
              <div class="text-h4 font-weight-thin">
                {{tituloEnContraUltimos}}
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
  
      <!-- linea de analisis descripcion de goles a favor -->
      <v-row>
        <v-col cols="12">
          <v-card class="mx-auto text-center" color="indigo" dark>
            <v-card-text>
              <v-sheet color="rgba(0, 0, 0, .12)">
                <v-sparkline :value="golesPorTipoGrafica" line-width="10" color="white" height="50" padding="50"
                  type="bar" stroke-linecap="butt" show-labels :labels="etiquetasGoles" label-size="3.5">
                  <!-- No necesitamos usar un template aquí, solo el gráfico -->
                </v-sparkline>
              </v-sheet>
            </v-card-text>
            <v-card-text>
              <div class="text-h4 font-weight-thin">
                Descripción de los Goles a Favor
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
  
      <!-- linea de analisis descripcion de goles en contra -->
      <v-row>
        <v-col cols="12">
          <v-card class="mx-auto text-center" color="red" dark>
            <v-card-text>
              <v-sheet color="rgba(0, 0, 0, .12)">
                <v-sparkline :value="golesPorTipoGraficaContra" line-width="10" color="white" height="50" padding="50"
                  type="bar" stroke-linecap="butt" show-labels :labels="etiquetasGolesContra" label-size="3.5">
                  <!-- No necesitamos usar un template aquí, solo el gráfico -->
                </v-sparkline>
              </v-sheet>
            </v-card-text>
            <v-card-text>
              <div class="text-h4 font-weight-thin">
                Descripción de los Goles en Contra
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
  
      <!-- linea de analisis de MINUTAJE -->
      <v-row>
        <!-- minutaje goles a favor -->
        <v-col cols="6">
          <v-card class="mx-auto text-center" color="blue" dark>
            <v-card-text>
              <v-sheet color="rgba(0, 0, 0, .12)">
                <v-sparkline :value="golesFavorMinutaje" :labels="intervalos" line-width="10" color="blue" height="100"
                  padding="50" type="bar" stroke-linecap="butt" show-labels label-size="4.5" />
              </v-sheet>
            </v-card-text>
            <v-card-text>
              <div class="text-h4 font-weight-thin">
                Minutos de los goles a favor
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- mintaje goles en contra -->
        <v-col cols="6">
          <v-card class="mx-auto text-center" color="orange" dark>
            <v-card-text>
              <v-sheet color="rgba(0, 0, 0, .12)">
                <v-sparkline :value="golesContraMinutaje" :labels="intervalos" line-width="10" color="red" height="100"
                  padding="50" type="bar" stroke-linecap="butt" show-labels label-size="4.5" />
              </v-sheet>
            </v-card-text>
            <v-card-text>
              <div class="text-h4 font-weight-thin" color="red">
                Minutos de los goles en contra
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </template>
    
    <script>
    export default {
      data() {
        return {
          golesTablaBd: [],
  
          tituloEnContraUltimos: '',
          tituloAfavorUltimos:'',

          golesFavorGrafica: [],
          golesEnContraGrafica: [],
          
          // Array de números que corresponden a cada tipo de gol
          golesPorTipoGrafica: [],
          golesPorTipoGraficaContra: [],
  
          // Las etiquetas correspondientes a cada tipo de gol
          etiquetasGoles: [],
          etiquetasGolesContra: [],
  
          golesFavorMinutaje: [],  // Aquí se cargarán los datos de los goles
          golesContraMinutaje: [],
          intervalos: ['0-4', '5-9', '10-14', '15-19', '20-24', '25-29', '30-34', '35-39', '40-44', '45-49', '50-54', '55-59', '60-64', '65-69', '70-74', '75-79', '80-84', '85-89', '90+']
      
        }
      },
      computed: {
      },
      async mounted() {
          await this.fetchGolesData();
          await this.fetchPartidos();
          this.cambiarVisual(5); // Inicialmente muestra los últimos 5 partidos
      },
      methods: {
  
        async fetchPartidos() {
          const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
  
          try {
            const usuarioId = localStorage.getItem('userId');
  
            // Obtener el código de usuario
            const responseCodigo = await fetch(`${API_BASE_URL}/usuarios/${usuarioId}/codigo`);
            if (!responseCodigo.ok) {
              throw new Error('No se pudo obtener el código de usuario');
            }
            const codigoUsuario = await responseCodigo.text();
  
            // Obtener todos los partidos
            const responsePartidos = await fetch(`${API_BASE_URL}/partidos`);
            if (!responsePartidos.ok) {
              throw new Error('No se pudo obtener los partidos');
            }
            const partidos = await responsePartidos.json();
  
            // Filtrar los partidos del usuario
            const partidosUsuario = partidos.filter(partido => partido.codigo === codigoUsuario);
  
            // Extraer y procesar los datos necesarios
            const golesFavor = [];
            const golesEnContra = [];
  
            for (const partido of partidosUsuario) {
              // Interpretar el resultado del partido
              const [golesCasa, golesFuera] = partido.resultado.split('-').map(Number);
  
              // Determinar goles a favor y en contra basados en el lugar del partido
              if (partido.lugar === 'Casa') {
                golesFavor.push(golesCasa);
                golesEnContra.push(golesFuera);
              } else {
                golesFavor.push(golesFuera);
                golesEnContra.push(golesCasa);
              }
            }

            // Actualizar los datos para las sparklines
            this.partidos = partidosUsuario;
            this.golesFavorGrafica = golesFavor;
            this.golesEnContraGrafica = golesEnContra;
          } catch (error) {
            console.error('Error al obtener los datos de los partidos:', error);
          }
        },
  
  
        cambiarVisual(numeroPartidos) {
          // Si el número de partidos es 'all', usamos todos los partidos disponibles
          const datos = numeroPartidos === 'all' ? this.partidos : this.partidos.slice(-numeroPartidos);
  
          // Extraer goles a favor y en contra de los partidos seleccionados
          this.golesFavorGrafica = datos.map(partido => {
            const [golesCasa, golesFuera] = partido.resultado.split('-').map(Number);
            return partido.lugar === 'Casa' ? golesCasa : golesFuera;
          });
  
          this.golesEnContraGrafica = datos.map(partido => {
            const [golesCasa, golesFuera] = partido.resultado.split('-').map(Number);
            return partido.lugar === 'Casa' ? golesFuera : golesCasa;
          });
  
          // Actualizar los títulos
          if (numeroPartidos === 'all') {
            this.tituloAfavorUltimos = 'Todos los partidos a Favor';
            this.tituloEnContraUltimos = 'Todos los partidos en Contra';
          } else {
            this.tituloAfavorUltimos = `Últimos ${numeroPartidos} partidos a Favor`;
            this.tituloEnContraUltimos = `Últimos ${numeroPartidos} partidos en Contra`;
          }
        },
        
        async fetchGolesData() {
          try {
            const usuarioId = localStorage.getItem('userId');
            const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
            // Obtener el código de usuario
            const responseCodigo = await fetch(`${API_BASE_URL}/usuarios/${usuarioId}/codigo`);
            if (!responseCodigo.ok) {
              throw new Error('No se pudo obtener el código de usuario');
            }
            const codigoUsuario = await responseCodigo.text();
  
            // Obtener los goles a favor filtrados por el código de usuario
            const responseGoles = await fetch(`${API_BASE_URL}/goles/filtrar/${codigoUsuario}`);
            if (!responseGoles.ok) {
              throw new Error('Error fetching data');
            }
            const data = await responseGoles.json();
            const goles = [...data];
            this.golesTablaBd = data;
  
            // Obtener los goles en contra filtrados por el código de usuario
            const responseGolesContra = await fetch(`${API_BASE_URL}/golrival/codigo/${codigoUsuario}`);
            if (!responseGoles.ok) {
              throw new Error('Error fetching data');
            }
            const dataContra = await responseGolesContra.json();
            const golesContra = [...dataContra];
            this.golesTablaBdContra = dataContra;
  
            // Obtener la lista de jugadores
            const responseJugadores = await fetch(`${API_BASE_URL}/jugadores/usuario/${codigoUsuario}`);
            if (!responseJugadores.ok) {
              throw new Error('Error fetching players data');
            }
            const jugadores = await responseJugadores.json();
            this.jugadores = jugadores;
  
            const golesPorIntervalo = {};
            const golesContraPorIntervalo = {};
  
            // Inicializar los contadores para los intervalos de 5 en 5 minutos
            for (let i = 0; i <= 90; i += 5) {
              const intervalo = `${i}-${i + 4}`;
              golesPorIntervalo[intervalo] = 0;
              golesContraPorIntervalo[intervalo] = 0;
              this.intervalos.push(intervalo); // Agregar intervalos a las etiquetas
            }
  
            // Recorrer los goles a favor
            data.forEach(gol => {
              const minuto = parseInt(gol.minuto, 10);
              const grupo = Math.floor(minuto / 5) * 5;
              const intervalo = `${grupo}-${grupo + 4}`;
              golesPorIntervalo[intervalo]++;
            });
  
            // Recorrer los goles en contra (suponiendo que dataContra está disponible)
            dataContra.forEach(gol => {
              const minuto = parseInt(gol.minuto, 10);
              const grupo = Math.floor(minuto / 5) * 5;
              const intervalo = `${grupo}-${grupo + 4}`;
              golesContraPorIntervalo[intervalo]++;
            });
  
            // Asignar los valores a las variables
            this.golesFavorMinutaje = Object.values(golesPorIntervalo);
            this.golesContraMinutaje = Object.values(golesContraPorIntervalo);
  
            /* los obj en golesTablaBd tienen -->
                codigo:
                id: 
                idJugadorAsistente: 
                idJugadorGol: 
                idRivalGol: 
                jornada: 
                minuto: 
            */
  
            // Definir los tipos de goles que se van a contar
            const descripcionesGol = [
              'Penalti',
              'Libre-directo',
              'Balón Parado',
              'Centro lateral',
              'Tras presión',
              'Contrataque',
              'Ata-posicional',
              'Mano-mano',
              'Disparo lejano',
              'Pase atras'
            ];
            //ESTA ES LA LOGICA PARA LA TABLA DE DESCRIPCION DE GOLES ----------------------------------------- A FAVOR
            // Crear un array para contar las ocurrencias de cada tipo de gol
            const golesPorTipoGrafica = new Array(descripcionesGol.length).fill(0);
  
            // Recorrer los goles y contar las descripciones
            goles.forEach(gol => {
              const descripcion = gol.descripcion;
              const index = descripcionesGol.indexOf(descripcion);
              if (index !== -1) {
                golesPorTipoGrafica[index]++;
              }
            });
  
            // Crear el array etiquetasGoles
            const etiquetasGoles = descripcionesGol.map((descripcion, index) => {
              return `${descripcion}: ${golesPorTipoGrafica[index]}`;
            });
  
            // Actualizar las propiedades de data
            this.golesPorTipoGrafica = golesPorTipoGrafica;
            this.etiquetasGoles = etiquetasGoles;
  
            //ESTA ES LA LOGICA PARA LA TABLA DE DESCRIPCION DE GOLES ----------------------------------------- EN CONTRA
            // Crear un array para contar las ocurrencias de cada tipo de gol en contra
            const golesPorTipoGraficaContra = new Array(descripcionesGol.length).fill(0);
  
            // Recorrer los goles y contar las descripciones
            golesContra.forEach(gol => {
              const descripcion = gol.descripcion;
              const index = descripcionesGol.indexOf(descripcion);
              if (index !== -1) {
                golesPorTipoGraficaContra[index]++;
              }
            });
  
            // Crear el array etiquetasGoles
            const etiquetasGolesContra = descripcionesGol.map((descripcion, index) => {
              return `${descripcion}: ${golesPorTipoGraficaContra[index]}`;
            });
  
            // Actualizar las propiedades de data
            this.golesPorTipoGraficaContra = golesPorTipoGraficaContra;
            this.etiquetasGolesContra = etiquetasGolesContra;
  
  
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        }
      }
    }
    </script>
    
    <style scoped>
    .text-center {
      text-align: center;
    }
  
    /* Estilos adicionales según sea necesario */
    .text-center {
      text-align: center;
    }
  
    .text-white {
      color: white !important;
    }
  
    .v-card .v-card-title,
    .v-card .v-card-text,
    .v-card .v-timeline-item {
      color: white !important;
    }
  
  
    .custom-sparkline-label {
      font-size: 6px;
      /* Ajusta este tamaño según tus necesidades */
    }
  
  </style>