<template>
  <div>
    <!-- Primera fila con 3 columnas -->
    <v-row class="mb-4">

      <!-- Columna 1: Total Goles a Favor -->
      <v-col cols="4">
        <v-card class="pa-4 mb-4" outlined elevation="15">
          <v-card-text>
            <div class="text-center mt-4">
              <div class="text-h6">TOTAL GOLES A FAVOR</div>
              <div class="text-h2 font-weight-bold">{{totalGolesFavor}}</div>
              <v-icon color="green" size="48">mdi-soccer</v-icon>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Columna 2: Total Asistencias -->
      <v-col cols="4">
        <v-card class="pa-4 mb-4" outlined elevation="15">
          <v-card-text>
            <div class="text-center mt-4">
              <div class="text-h6">TOTAL ASISTENCIAS</div>
              <div class="text-h2 font-weight-bold">{{totalAsistenciasFavor}}</div>
              <v-icon color="blue" size="48">fa-solid fa-parachute-box</v-icon>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Columna 3: Total Goles en Contra -->
      <v-col cols="4">
        <v-card class="pa-4 mb-4" outlined elevation="15">
          <v-card-text>
            <div class="text-center mt-4">
              <div class="text-h6">TOTAL GOLES EN CONTRA</div>
              <div class="text-h2 font-weight-bold">{{totalGolesContra}}</div>
              <v-icon color="red" size="48">mdi-soccer</v-icon>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- segunda fila con 4 columnas -->
    <v-row>
      <!-- Columna 1: Top Goleadores -->
      <v-col cols="3">
        <v-card class="pa-4 mb-4" outlined elevation="15" color="green">
          <v-card-text>
            <div class="text-center">
              <div class="text-h5 bold mb-2">TOP GOLEADORES</div>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">JUGADOR</th>
                      <th class="text-right">Nº GOLES</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(jugador, index) in topGoleadores.slice(0, 5)" :key="index">
                      <td class="text-left">{{ jugador.jugador }}</td>
                      <td class="text-right">{{ jugador.numeroGoles }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Columna 2: Últimos 5 Goleadores -->
      <v-col cols="3">
        <v-card class="pa-4 mb-4" outlined elevation="15" color="green">
          <v-card-text>
            <div class="text-center">
              <div class="text-h5 mb-2">ÚLTIMOS 5 GOLEADORES</div>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">JUGADOR</th>
                      <th class="text-right">JORNADA</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(goleador, index) in ultimosGoleadores.slice(0, 5)" :key="index">
                      <td class="text-left">{{goleador.jugador }}</td>
                      <td class="text-right">{{goleador.jornada}}</td>
                      <!-- Ajusta aquí la jornada según tus datos reales -->
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Columna 3: Top Asistentes -->
      <v-col cols="3">
        <v-card class="pa-4 mb-4" outlined elevation="15" color="blue">
          <v-card-text>
            <div class="text-center">
              <div class="text-h5 mb-2">TOP ASISTENTES</div>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">JUGADOR</th>
                      <th class="text-right">Nº ASISTENCIAS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(jugador, index) in topAsistentes.slice(0, 5)" :key="index">
                      <td class="text-left">{{ jugador.jugador }}</td>
                      <td class="text-right">{{ jugador.numeroAsistencias }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Columna 4: Últimos 5 Asistentes -->
      <v-col cols="3">
        <v-card class="pa-4 mb-4" outlined elevation="15" color="blue">
          <v-card-text>
            <div class="text-center">
              <div class="text-h5 mb-2">ÚLTIMOS 5 ASISTENTES</div>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">JUGADOR</th>
                      <th class="text-right">JORNADA</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(asistente, index) in ultimosAsistentes.slice(0, 5)" :key="index">
                      <td class="text-left">{{asistente.jugador}}</td>
                      <td class="text-right">{{asistente.jornada}}</td>
                      <!-- Ajusta aquí la jornada según tus datos reales -->
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Nueva fila para TOP GLOBAL PARTICIPACIONES DE GOL -->
    <v-row>
      <v-col cols="3"></v-col>
      <v-col>
        <v-card class="pa-4" outlined elevation="15" >
          <v-card-text>
            <div class="text-center">
              <div class="text-h5 mb-2">TOP GLOBAL PARTICIPACIONES DE GOL</div>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">JUGADOR</th>
                      <th class="text-right">GOLES</th>
                      <th class="text-right">ASISTENCIAS</th>
                      <th class="text-right">TOTAL PARTICIPACIONES DE GOL</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(participacion, index) in participaciones.slice(0, 35)" :key="index">
                      <td class="text-left custom-text">{{ participacion.jugador }}</td>
                      <td class="text-right ">{{ participacion.goles }}</td>
                      <td class="text-right ">{{ participacion.asistencias }}</td>
                      <td class="text-right custom-text">{{ participacion.totalParticipaciones }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="3"></v-col>
    </v-row>
  </div>
</template>
  
  <script>
  export default {
    data() {
      return {
        totalGolesFavor:0,
        totalAsistenciasFavor:0,
        totalGolesContra:0,
        topGoleadores: [],
        topAsistentes: [],
        ultimosGoleadores: [],
        ultimosAsistentes: [],
        participaciones: [],
      }
    },
    computed: {
    },
    async mounted() {
        await this.fetchGolesData();
        await this.fetchPartidos();
    },
    methods: {

      async fetchPartidos() {
        const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

        try {
          const usuarioId = localStorage.getItem('userId');

          // Obtener el código de usuario
          const responseCodigo = await fetch(`${API_BASE_URL}/usuarios/${usuarioId}/codigo`);
          if (!responseCodigo.ok) {
            throw new Error('No se pudo obtener el código de usuario');
          }
          const codigoUsuario = await responseCodigo.text();

          // Obtener todos los partidos
          const responsePartidos = await fetch(`${API_BASE_URL}/partidos`);
          if (!responsePartidos.ok) {
            throw new Error('No se pudo obtener los partidos');
          }
          const partidos = await responsePartidos.json();

          // Filtrar los partidos del usuario
          const partidosUsuario = partidos.filter(partido => partido.codigo === codigoUsuario);

          // Extraer y procesar los datos necesarios
          const golesFavor = [];
          const golesEnContra = [];

          for (const partido of partidosUsuario) {
            // Interpretar el resultado del partido
            const [golesCasa, golesFuera] = partido.resultado.split('-').map(Number);

            // Determinar goles a favor y en contra basados en el lugar del partido
            if (partido.lugar === 'Casa') {
              golesFavor.push(golesCasa);
              golesEnContra.push(golesFuera);
            } else {
              golesFavor.push(golesFuera);
              golesEnContra.push(golesCasa);
            }
          }

          // Calcular el total de goles en contra
          const totalGolesEnContra = golesEnContra.reduce((acc, goles) => acc + goles, 0);

          // Actualizar los datos
          this.partidos = partidosUsuario;
          this.totalGolesContra = totalGolesEnContra; // Guarda el total de goles en contra en el estado del componente

        } catch (error) {
          console.error('Error al obtener los datos de los partidos:', error);
        }
      },

      async fetchGolesData() {
        try {
          const usuarioId = localStorage.getItem('userId');
          const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
          // Obtener el código de usuario
          const responseCodigo = await fetch(`${API_BASE_URL}/usuarios/${usuarioId}/codigo`);
          if (!responseCodigo.ok) {
            throw new Error('No se pudo obtener el código de usuario');
          }
          const codigoUsuario = await responseCodigo.text();

          // Obtener los goles a favor filtrados por el código de usuario
          const responseGoles = await fetch(`${API_BASE_URL}/goles/filtrar/${codigoUsuario}`);
          if (!responseGoles.ok) {
            throw new Error('Error fetching data');
          }
          const data = await responseGoles.json();
          this.golesTablaBd = data;

          // Obtener los goles en contra filtrados por el código de usuario
          const responseGolesContra = await fetch(`${API_BASE_URL}/golrival/codigo/${codigoUsuario}`);
          if (!responseGoles.ok) {
            throw new Error('Error fetching data');
          }
          const dataContra = await responseGolesContra.json();
          this.golesTablaBdContra = dataContra;

          // Obtener la lista de jugadores
          const responseJugadores = await fetch(`${API_BASE_URL}/jugadores/usuario/${codigoUsuario}`);
          if (!responseJugadores.ok) {
            throw new Error('Error fetching players data');
          }
          const jugadores = await responseJugadores.json();
          this.jugadores = jugadores;

          //ESTA ES LA LOGICA PARA RESTO DE COSAS DE GOLES -----------------------------------------
          // El total de objetos en el array golesTablaBd
          this.totalGolesFavor = this.golesTablaBd.length;

          // Contar los objetos que el idJugadorAsistente es diferente a 0
          this.totalAsistenciasFavor = this.golesTablaBd.filter(gol => gol.idJugadorAsistente !== 0).length;

          // Calcular el top goleadores
          const goleadoresCount = this.golesTablaBd.reduce((acc, gol) => {
            // Incrementar el contador de goles para el idJugadorGol correspondiente
            acc[gol.idJugadorGol] = (acc[gol.idJugadorGol] || 0) + 1;
            return acc;
          }, {});

          // Convertir el objeto de recuento en un array de objetos
          this.topGoleadores = Object.entries(goleadoresCount)
          .filter(([idJugador, numeroGoles]) => parseInt(idJugador) !== 0) // Filtrar si idJugador es 0
          .map(([idJugador, numeroGoles]) => {
            // Buscar el nombre del jugador en la lista de jugadores
            const jugador = this.jugadores.find(j => j.id === parseInt(idJugador));
            return {
              jugador: jugador ? jugador.apodo : idJugador, // Si el jugador no se encuentra, usar idJugador
              numeroGoles
            };
          });

          // Ordenar por número de goles de forma descendente
          this.topGoleadores.sort((a, b) => b.numeroGoles - a.numeroGoles);

          // Calcular el top asistentes
          const asistentesCount = this.golesTablaBd.reduce((acc, gol) => {
            if (gol.idJugadorAsistente !== 0) { // Solo contar asistencias válidas
              acc[gol.idJugadorAsistente] = (acc[gol.idJugadorAsistente] || 0) + 1;
            }
            return acc;
          }, {});

          // Convertir el objeto de recuento en un array de objetos
          this.topAsistentes = Object.entries(asistentesCount)
          .filter(([idJugador, numeroAsistencias]) => parseInt(idJugador) !== 0) // Filtrar si idJugador es 0
          .map(([idJugador, numeroAsistencias]) => {
            const jugador = this.jugadores.find(j => j.id === parseInt(idJugador));
            return {
              jugador: jugador ? jugador.apodo : idJugador,
              numeroAsistencias
            };
          });

          // Ordenar por número de asistencias de forma descendente
          this.topAsistentes.sort((a, b) => b.numeroAsistencias - a.numeroAsistencias);

          // Función para obtener los 5 mejores jugadores por jornada
          const obtenerUltimos = (datos, campoId, campoJornada) => {
            // Ordenar por jornada en orden descendente
            const ordenados = datos.slice().sort((a, b) => b[campoJornada] - a[campoJornada]);

            // Filtrar los 5 jugadores con jornadas más altas, evitando duplicados
            const mejores = [];
            const idsVistos = new Set();

            for (const item of ordenados) {
              if (mejores.length >= 5) break;
              if (!idsVistos.has(item[campoId])) {
                idsVistos.add(item[campoId]);
                mejores.push(item);
              }
            }

            return mejores;
          };

          // Obtener los últimos goleadores
          this.ultimosGoleadores = obtenerUltimos(this.golesTablaBd, 'idJugadorGol', 'jornada')
          .filter(gol => gol.idJugadorGol !== 0) // Filtrar para excluir goleadores con idJugadorGol igual a 0
          .map(gol => ({
            jugador: this.jugadores.find(j => j.id === gol.idJugadorGol)?.apodo || gol.idJugadorGol,
            jornada: gol.jornada
          }));

          // Obtener los últimos asistentes
          this.ultimosAsistentes = obtenerUltimos(this.golesTablaBd, 'idJugadorAsistente', 'jornada')
            .filter(gol => gol.idJugadorAsistente !== 0) // Filtrar para excluir asistentes con idJugadorAsistente igual a 0
            .map(gol => ({
              jugador: this.jugadores.find(j => j.id === gol.idJugadorAsistente)?.apodo || gol.idJugadorAsistente,
              jornada: gol.jornada
            }));


          // Creamos el array de participaciones
          this.participaciones = this.jugadores.map((jugador) => {
            // Recuento de goles
            const goles = this.golesTablaBd.filter(gol => gol.idJugadorGol === jugador.id).length;

            // Recuento de asistencias
            const asistencias = this.golesTablaBd.filter(gol => gol.idJugadorAsistente === jugador.id).length;

            // Total de participaciones
            const totalParticipaciones = goles + asistencias;

            // Devolvemos el objeto con los datos del jugador y su rendimiento
            return {
              jugador: jugador.apodo || `${jugador.nombre} ${jugador.apellido}`, // Usa el apodo si está disponible, sino el nombre completo
              goles: goles,
              asistencias: asistencias,
              totalParticipaciones: totalParticipaciones
            }
          });

          // Eliminar jugadores con 0 participaciones
          this.participaciones = this.participaciones.filter(participacion => participacion.totalParticipaciones > 0);

          // Ordenar por el número de participaciones (de mayor a menor)
          this.participaciones.sort((a, b) => b.totalParticipaciones - a.totalParticipaciones);

        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    }
  }
  </script>
  
  <style scoped>
  .text-center {
    text-align: center;
  }

  /* Estilos adicionales según sea necesario */
  .text-center {
    text-align: center;
  }

  .text-white {
    color: white !important;
  }

  .v-card .v-card-title,
  .v-card .v-card-text,
  .v-card .v-timeline-item {
    color: white !important;
  }

  .custom-text {
    font-weight: bold;
  }

  .custom-sparkline-label {
    font-size: 6px;
    /* Ajusta este tamaño según tus necesidades */
  }

</style>