<template>
  <div>
    <v-text-field
      v-model="formData.nombre"
      label="Nombre del Equipo"
      :rules="nombreRules"
      append-icon="*"
      :disabled="disableFields"
    ></v-text-field>

    <v-select
      v-model="formData.categoria"
      label="Categoría"
      :items="categoriaItems"
      :rules="categoriaRules"
      append-icon="*"
      :disabled="disableFields"
    ></v-select>

    <v-text-field
      v-model="formData.club"
      label="Club"
      :rules="clubRules"
      append-icon="*"
      :disabled="disableFields"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  props: {
    initialData: {
      type: Object,
      default: null,
    },
    disableFields: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      formData: {
        nombre: '',
        categoria: '',
        club: '',
      },
      showRules: false,
      categoriaItems: [
        'F11-Senior',
        'F11-Juvenil',
        'F11-Cadete',
        'F11-Infantil',
        'F8-Alevin',
        'F8-Benjamin'
      ],
    };
  },
  computed: {
    nombreRules() {
      return [
        (v) => (!!v || this.showRules) || 'El nombre del equipo es obligatorio',
      ];
    },
    categoriaRules() {
      return [
        (v) => (!!v || this.showRules) || 'La categoría del equipo es obligatoria',
      ];
    },
    clubRules() {
      return [
        (v) => (!!v || this.showRules) || 'El club del equipo es obligatorio',
      ];
    },
  },
  methods: {
    activateRules() {
      this.showRules = true;
    },
    getFormData() {
      return { ...this.formData };
    },
    resetForm() {
      this.showRules = false;
      this.formData = {
        nombre: '',
        categoria: '',
        club: '',
      };
    },
  },
  watch: {
    initialData: {
      immediate: true,
      handler(newData) {
        if (newData) {
          this.formData = {
            nombre: newData.nombre,
            categoria: newData.categoria,
            club: newData.club,
          };
        }
      },
    },
  },
};
</script>

<style scoped>
/* Estilos específicos para el componente */
</style>
