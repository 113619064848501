<template>
  <v-card class="mx-auto" max-width="400">
    <v-card-title class="headline">
      Actualizar Usuario
    </v-card-title>

    <v-card-text>
      <usuario-form ref="UsuarioForm" :initialData="usuarioData" :disablePasswordFields="true" :equipos="equipos"></usuario-form>
    </v-card-text>

    <v-card-actions>
      <v-btn @click="actualizarUsuario" color="primary">
        Actualizar
      </v-btn>
      <v-btn @click="volverAUsuarios" color="primary">
        Volver
      </v-btn>
    </v-card-actions>

    <v-alert
      v-if="alertMessage"
      dense
      :type="alertType"
    >
      {{ alertMessage }}
    </v-alert>
  </v-card>
</template>

<script>
import UsuarioForm from '../../components/UsuarioComponents/UsuarioForm.vue';

export default {
  components: {
    UsuarioForm,
  },
  data() {
    return {
      alertMessage: null,
      alertType: null,
      usuarioData: null,
      equipos: [], // Inicializa equipos como un arreglo vacío
    };
  },
  methods: {
    async cargarDatosUsuario(id) {
      try {
        const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

        const response = await fetch(`${API_BASE_URL}/usuarios/${id}`);
        if (!response.ok) {
          throw new Error('Error al obtener los datos del usuario');
        }
        
        const userData = await response.json();
        // Asigna los datos del usuario a la propiedad 'usuarioData'
        this.usuarioData = userData;
      } catch (error) {
        console.error('Error al cargar los datos del usuario:', error);
        this.setAlert('Error al cargar los datos del usuario, pulse en volver e intentelo de nuevo.', 'error');
      }
    },
    async actualizarUsuario() {
      const usuarioFormRef = this.$refs.UsuarioForm;
      const usuarioFormData = usuarioFormRef.getFormData();
      usuarioFormRef.activateRules();

      // Validaciones
      if (!usuarioFormData.nombre || !usuarioFormData.apellido || !usuarioFormData.username || !usuarioFormData.codigo) {
        this.setAlert('Hay campos incompletos. Por favor, rellene todos los campos.', 'error');
        return;
      }

      if (!usuarioFormData.codigoManual && usuarioFormData.showCodigoManual) {
        this.setAlert('Por favor, introduzca el código manual.', 'error');
        return;
      }

      if (usuarioFormData.codigoManual !== String(usuarioFormData.codigo) && usuarioFormData.showCodigoManual) {
        this.setAlert('El código introducido no es el correspondiente al equipo seleccionado, introduce bien el código o selecciona bien el equipo. En caso de olvidar el código contactar con el administrador -> pablogomezperez01@gmail.com', 'error');
        return;
      }

      try {
        // Realizar la solicitud PUT o PATCH con los datos actualizados del formulario
        const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

        const response = await fetch(`${API_BASE_URL}/usuarios/actualizar/${this.usuarioData.id}`, {
          method: 'PUT', // o 'PATCH' según tu API
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            nombre: usuarioFormData.nombre,
            apellido: usuarioFormData.apellido,
            username: usuarioFormData.username,
            password: usuarioFormData.password, // Asegúrate de ajustar esto según la lógica de tu API
            codigo: usuarioFormData.codigo.toString(),
          }),
        });

        if (response.ok) {
          // El usuario se ha actualizado correctamente
          this.setAlert('El usuario se ha actualizado correctamente', 'success');
          this.$router.push({ name: 'InicioMenu' });
          // En algún lugar de tu código donde quieras forzar un refresh
        window.location.reload();
        } else {
          // Ocurrió un error al actualizar el usuario
          let errorMessage = 'Error al actualizar el usuario.';

          try {
            const responseText = await response.text();
            errorMessage = responseText || errorMessage;
          } catch (textError) {
            console.error('Error al obtener el texto de la respuesta:', textError);
          }

          this.setAlert(errorMessage, 'error');
        }

      } catch (error) {
        console.error('Error al realizar la solicitud:', error);
        this.setAlert('Ocurrió un error. Por favor, inténtelo de nuevo.', 'error');
      }
    },
    volverAUsuarios() {
      
      const id = localStorage.getItem('userId')

        if (id == 0 || id == '0') {

          // Redirige a la página de usuarios
          this.$router.push('/usuarios');

        } else {
          this.$router.push({ name: 'ObservarUsuario', params: { id } });
        }
    },
    setAlert(message, type) {
      this.alertMessage = message;
      this.alertType = type;
    },
    async cargarEquipos() {
      try {
        const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

        const response = await fetch(`${API_BASE_URL}/equipos`);
        if (!response.ok) {
          throw new Error('No se pudo obtener la lista de equipos');
        }
        const data = await response.json();
        //this.equipos = data; // Asigna los datos de los equipos a la propiedad 'equipos'
        this.equipos = data.map(equipo => ({
          ...equipo,
          nombreCompuesto: `${equipo.nombre} > ${equipo.club}`
        }));

      } catch (error) {
        console.error('Error al cargar los equipos:', error);
        this.setAlert('Error al cargar los equipos. Por favor, intenta nuevamente.', 'error');
      }
    },
  },
  created() {
    // Obtén el ID del usuario de los parámetros de la ruta
    const usuarioId = this.$route.params.id;
    // Llama a cargarEquipos para obtener la lista de equipos
    this.cargarEquipos();
    // Carga los datos del usuario al iniciar la página
    this.cargarDatosUsuario(usuarioId);
    
  },
};
</script>

<style scoped>
/* Puedes agregar estilos específicos para tu componente aquí */
</style>
