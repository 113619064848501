<template>
    <v-card class="mx-auto" max-width="2000">
      <v-card-title class="headline">
        Observar Informe
      </v-card-title>
      <v-card-text>
        <informe-form ref="InformeForm" :initialData="informeData" :disableFields="true"></informe-form>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="volverAListado" color="primary">
          Volver
        </v-btn>
      </v-card-actions>
    </v-card>
  </template>
  
  <script>
  import InformeForm from '../../../components/InformeComponents/InformeForm.vue';
  
  export default {
    components: {
      InformeForm,
    },
    data() {
      return {
        informeData: null,
      };
    },
    methods: {
      async cargarDatosInforme(id) {
        const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
        const response = await fetch(`${API_BASE_URL}/informes/${id}`);
        if (!response.ok) {
          this.$router.push({ name: 'Informes' }); // Redirigir si hay un error
          return;
        }
        this.informeData = await response.json();
      },
      volverAListado() {
        this.$router.push({ name: 'Informes' });
      },
    },
    created() {
      const id = this.$route.params.id;
      this.cargarDatosInforme(id);
    },
  };
  </script>
  
  <style scoped>
  /* Estilos específicos del componente */
  </style>
  