<template>
    <v-container>
        <!-- Card Informativo -->
        <v-alert v-if="showAlert" dismissible type="warning" closable="true" icon="mdi-alert"  class="text-h6">
            <template v-slot:title>
                Aviso Importante
            </template>
            <span>
                Los jugadores que han participado ya en partidos no pueden ser eliminados.
            </span>
        </v-alert>
        <v-alert ref="alert" :value="true"  v-model="alert.show" :type="alert.type">
            {{ alert.message }}
        </v-alert>
        <v-card-title class="headline">
            Listado de Jugadores de la plantilla
            <v-spacer></v-spacer> <!-- Agrega un espacio flexible -->
            <v-btn @click="redirigirACrearUsuario" icon>
                <v-icon>fas fa-plus</v-icon> <!-- Ajusta el ícono según tu necesidad -->
            </v-btn>
        </v-card-title>
        <v-data-table :headers="headers" :items="jugadores" class="elevation-1">
            <template v-slot:item="{ item }">
                <tr>
                    <td>
                        <v-btn @click="redirigirAActualizar(item.id)" icon>
                            <v-icon>fas fa-pencil-alt</v-icon>
                        </v-btn>
                        <v-btn @click="redirigirAObservar(item.id)" icon>
                            <v-icon>fas fa-eye</v-icon>
                        </v-btn>
                        <v-btn  @click="mostrarConfirmacionEliminar(item.id)" icon>
                            <v-icon>fas fa-trash-alt</v-icon>
                        </v-btn>
                    </td>
                    <td>{{ item.nombre }}</td>
                    <td>{{ item.apellido }}</td>
                    <td>{{ item.apodo }}</td>
                </tr>
            </template>
        </v-data-table>
        <!-- Modal de Confirmación para Eliminar -->



        <confirmacion-eliminar-jugador :showDialog="confirmacionEliminar" :closeDialog="cerrarConfirmacionEliminar"
            :confirmarAccionUsuario="eliminarjugador" :mensajeConfirmacion="mensajeConfirmacion" />




    </v-container>
</template>



<script>

import ConfirmacionEliminarJugador from '../../components/ConfirmacionEliminar.vue';


export default {
    components: {
        ConfirmacionEliminarJugador,
    },
    data() {
        return {
            showAlert: true, // Controla la visibilidad del alert
            headers: [
                { text: 'Acciones', value: 'acciones', sortable: false },
                { text: 'Nombre', value: 'nombre' },
                { text: 'Apellido', value: 'apellido' },
                { text: 'Apodo', value: 'apodo' },
            ],
            jugadores: [],
            confirmacionEliminar: false,
            jugadorAEliminarId: null,
            mensajeConfirmacion: '',
            alert: {
                show: false,
                type: 'success', // Puedes cambiar esto dinámicamente según el resultado
                message: '',
            },
        };
    },
    created() {
        // Obtén el mensaje de éxito de los parámetros de la ruta
        const successMessage = this.$route.params.successMessage;

        // Si hay un mensaje de éxito, muéstralo usando la función de alerta
        if (successMessage) {
            this.success(successMessage);
        }
    },
    methods: {
        success(message) {
            this.alert.show = true;
            this.alert.type = 'success';
            this.alert.message = message;
        },
        error(message) {
            this.alert.show = true;
            this.alert.type = 'error';
            this.alert.message = message;
        },
        redirigirAActualizar(id) {
            // Redirige a la página de actualización con el ID del jugador seleccionado
            this.$router.push({ name: 'ModificarJugador', params: { id } });
        }, redirigirAObservar(id) {
            // Redirige a la página de actualización con el ID del jugador seleccionado
            this.$router.push({ name: 'ObservarJugador', params: { id } });
        },
        mostrarAlerta(accion, id) {
            alert(`Botón ${accion} clickeado para el jugador con ID ${id}`);
        },
        mostrarConfirmacionEliminar(id) {
            this.jugadorAEliminarId = id;
            this.mensajeConfirmacion = `¿Estás seguro de que deseas eliminar el jugador con ID ${id}?`;
            this.confirmacionEliminar = true;
        },
        cerrarConfirmacionEliminar() {
            this.jugadorAEliminarId = null;
            this.mensajeConfirmacionEliminar = '';
            this.confirmacionEliminar = false;
        },
        eliminarjugador() {
            //alert("Función todavía no disponible, espera siguiente actualización");
            // Almacena el ID antes de realizar la petición
            const idAEliminar = this.jugadorAEliminarId;
            const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
            // Realizar la petición DELETE a la API para eliminar el jugador
            fetch(`${API_BASE_URL}/jugadores/borrar/${this.jugadorAEliminarId}`, {
                method: 'DELETE',
            })
                .then(response => {
                    if (response.ok) {
                        // jugador eliminado correctamente
                        this.success(`Jugador con ID ${idAEliminar} eliminado correctamente.`);
                    } else {
                        // Ocurrió un error al eliminar el jugador
                        this.error(`Error al eliminar el jugador con ID ${idAEliminar}, este ya debió de haber sido eliminado previamente o esta asociado a uno o más partidos.`);
                    }
                })
                .catch(error => {
                    console.error('Error al realizar la solicitud DELETE:', error);
                    // Mostrar mensaje de error en caso de fallo en la solicitud
                    this.error('Error al realizar la solicitud DELETE. Por favor, intenta nuevamente.');
                })
                .finally(() => {
                    // Cerrar el modal después de eliminar o en caso de error
                    this.cerrarConfirmacionEliminar();
                    // Recargar la lista de jugadores después de eliminar
                    this.cargarjugadores();
                });
        },
        async cargarjugadores() {
            const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
            const usuarioId = localStorage.getItem('userId');

            const response = await fetch(`${API_BASE_URL}/usuarios/${usuarioId}/codigo`);
            if (!response.ok) {
                throw new Error('No se pudo obtener el código de usuario');
            }
            const codigoUsuario = await response.text(); // Suponiendo que el código de usuario es un texto simple

            // Realizar la petición GET a la API de jugadores
            // Usar el código de usuario para hacer la solicitud GET a los jugadores
            const responseJugadores = await fetch(`${API_BASE_URL}/jugadores/usuario/${codigoUsuario}`);
            if (!responseJugadores.ok) {
                throw new Error('No se pudo obtener la lista de jugadores');
            }
            const data = await responseJugadores.json();

            // Asignar los datos a la propiedad 'jugadores'
            this.jugadores = data;


        },
        redirigirACrearUsuario() {
            // Redirige a la página de creación de usuario
            this.$router.push({ name: 'CrearJugador' });
        },
    },
    mounted() {
        this.cargarjugadores();
    },
};
</script>

<style scoped>
/* Estilos específicos del componente */
</style>