<template>
    <v-card class="mx-auto" max-width="400">
      <v-card-title class="headline">
        Actualizar Jugador
      </v-card-title>
  
      <v-card-text>
        <jugador-form ref="JugadorForm" :initialData="jugadorData" :disablePasswordFields="true"></jugador-form>
      </v-card-text>
  
      <v-card-actions>
        <v-btn @click="actualizarJugador" color="primary">
          Actualizar
        </v-btn>
        <v-btn @click="volverAJugadors" color="primary">
          Volver
        </v-btn>
      </v-card-actions>
  
      <v-alert
        v-if="alertMessage"
        dense
        :type="alertType"
      >
        {{ alertMessage }}
      </v-alert>
    </v-card>
  </template>
  
  <script>
  import JugadorForm from '../../components/JugadorComponents/JugadorForm.vue';
  
  export default {
    components: {
      JugadorForm,
    },
    data() {
      return {
        alertMessage: null,
        alertType: null,
        jugadorData: null,
      };
    },
    methods: {
      async cargarDatosJugador(id) {
        try {
          const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
          const response = await fetch(`${API_BASE_URL}/jugadores/${id}`);
          if (!response.ok) {
            throw new Error('Error al obtener los datos del Jugador');
          }
          const userData = await response.json();
          // Asigna los datos del Jugador a la propiedad 'JugadorData'
          this.jugadorData = userData;
        } catch (error) {
          console.error('Error al cargar los datos del Jugador:', error);
          this.setAlert('Error al cargar los datos del Jugador, pulse en volver e intentelo de nuevo.', 'error');
        }
      },
      async actualizarJugador() {
        const jugadorFormRef = this.$refs.JugadorForm;
        const jugadorFormData = jugadorFormRef.getFormData();
        jugadorFormRef.activateRules();
  
        // Validaciones
        if (!jugadorFormData.nombre || !jugadorFormData.apellido || !jugadorFormData.apodo) {
          this.setAlert('Hay campos incompletos. Por favor, rellene todos los campos.', 'error');
          return;
        }
  
        try {
          // Realizar la solicitud PUT o PATCH con los datos actualizados del formulario
          const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
          const response = await fetch(`${API_BASE_URL}/jugadores/modificar/${this.jugadorData.id}`, {
            method: 'PUT', // o 'PATCH' según tu API
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              nombre: jugadorFormData.nombre,
              apellido: jugadorFormData.apellido,
              apodo: jugadorFormData.apodo,
            }),
          });
  
          if (response.ok) {
            // El jugador se ha actualizado correctamente
            this.setAlert('El jugador se ha actualizado correctamente', 'success');
          } else {
            // Ocurrió un error al actualizar el jugador
            let errorMessage = 'Error al actualizar el jugador.';
  
            try {
              const responseText = await response.text();
              errorMessage = responseText || errorMessage;
            } catch (textError) {
              console.error('Error al obtener el texto de la respuesta:', textError);
            }
  
            this.setAlert(errorMessage, 'error');
          }
  
        } catch (error) {
          console.error('Error al realizar la solicitud:', error);
          this.setAlert('Ocurrió un error. Por favor, inténtelo de nuevo.', 'error');
        }
      },
      volverAJugadors() {
        // Redirige a la página de Jugadores
        this.$router.push('/plantilla');
      },
      setAlert(message, type) {
        this.alertMessage = message;
        this.alertType = type;
      },
    },
    created() {
      // Obtén el ID del jugador de los parámetros de la ruta
      const jugadorId = this.$route.params.id;
      // Carga los datos del jugador al iniciar la página
      this.cargarDatosJugador(jugadorId);
    },
  };
  </script>
  
  <style scoped>
  /* Puedes agregar estilos específicos para tu componente aquí */
  </style>
  