<template>
  <v-app id="inspire">
    <v-app-bar app color="grey" flat>
      <v-container fluid class="py-0 fill-height">
        <v-avatar class="mr-10" color="blue" size="40" v-if="isUserLoggedIn">
          {{ initial }}
        </v-avatar>

        <v-responsive max-width="250">
          <v-text-field v-if="isUserLoggedIn" prepend-inner-icon="mdi-account-multiple" readonly v-model="equipoNombre"
            color="blue" dense flat hide-details rounded solo-inverted>
          </v-text-field>
        </v-responsive>

        <v-responsive max-width="250">
          <v-text-field v-if="isUserLoggedIn" prepend-inner-icon="mdi-account" readonly v-model="getUserLogedIn"
            color="blue" dense flat hide-details rounded solo-inverted></v-text-field>
        </v-responsive>

        <v-btn v-if="isUserLoggedIn" v-for="action in Actions" :key="action" text @click="performAction(action)">
          {{ action }}
        </v-btn>

        <span class="app-name ml-3">Futgap</span>

      </v-container>
    </v-app-bar>

    <v-main class="grey lighten-3">
      <v-container fluid>
        <v-row>
          <!-- Usa v-if directamente en el v-col -->
          <v-col cols="3" v-if="isUserLoggedIn">
            <v-sheet rounded="lg">
              <v-list color="transparent">
                <!-- INICIO -->
                <v-list-item v-if="!isSuperAdminLoggedIn" link @click="navigate('INICIO')">
                  <v-list-item-icon>
                    <v-icon>mdi-home</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>INICIO</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <!-- JUGADORES (con opciones anidadas) -->
                <v-list-group no-action sub-group v-if="!isSuperAdminLoggedIn">
                  <template v-slot:activator>
                    <v-list-item-icon>
                      <v-icon>mdi-account-group</v-icon> <!-- Icono de "people-group" -->
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>JUGADORES</v-list-item-title>
                    </v-list-item-content>
                  </template>

                  <!-- Opciones dentro de "JUGADORES" -->
                  <v-list-item link @click="navigate('ESTADISTICAS JUGADOR')">
                    <v-list-item-icon>
                      <v-icon>mdi-account</v-icon> <!-- Icono de "database" -->
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>ESTADISTICAS IND</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item link @click="navigate('SANCIONES')">
                    <v-list-item-icon>
                      <v-icon>mdi-alert</v-icon> <!-- Icono de "triangle-exclamation" -->
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>SANCIONES</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item link @click="navigate('DESCONVOCATORIAS')">
                    <v-list-item-icon>
                      <v-icon>mdi-format-list-bulleted</v-icon> <!-- Icono de "list" -->
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>DESCONVOCATORIAS</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>

                <!-- GOLES Y ASISTENCIAS (con opciones anidadas) -->
                <v-list-group no-action sub-group v-if="!isSuperAdminLoggedIn">
                  <template v-slot:activator>
                    <v-list-item-icon>
                      <v-icon>mdi-soccer</v-icon> <!-- Icono de "soccer" -->
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>GOLES Y ASISTENCIAS</v-list-item-title>
                    </v-list-item-content>
                  </template>

                  <v-list-item link @click="navigate('RANKINGS')">
                    <v-list-item-icon>
                      <v-icon>mdi-star-circle</v-icon> <!-- Icono de "ranking-star" -->
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>RANKINGS</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <!-- Opciones dentro de "GOLES Y ASISTENCIAS" -->
                  <v-list-item link @click="navigate('ANALISIS GOLES')">
                    <v-list-item-icon>
                      <v-icon>mdi-chart-line</v-icon> <!-- Icono de "chart-line" -->
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>ANALISIS GOLES</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                </v-list-group>

                <!-- LISTADO ACTAS REGISTRADAS -->
                <v-list-item v-if="!isSuperAdminLoggedIn" link @click="navigate('LISTADO ACTAS REGISTRADAS')">
                  <v-list-item-icon>
                    <v-icon>mdi-format-list-bulleted</v-icon> <!-- Icono de "list" -->
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>LISTADO ACTAS REGISTRADAS</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <!-- BLOG DE INFORMES -->
                <v-list-item v-if="!isSuperAdminLoggedIn" link @click="navigate('BLOG INFORMES')">
                  <v-list-item-icon>
                    <v-icon>mdi-book-open</v-icon> <!-- Icono de "book" -->
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>BLOG DE INFORMES</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

            </v-sheet>
          </v-col>

          <v-col cols="9">
            <!-- Mueve este router-view fuera del v-sheet para evitar la duplicación -->
            <router-view></router-view>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <!-- Dialog for selecting category -->
    <v-dialog v-model="dialog" max-width="800px">
      <v-card>
        <v-card-title class="headline">
          ¿De qué categoría es el acta del partido que deseas crear?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" tonal outlined @click="createActa('NuevoActa')">F11</v-btn>
          <v-btn color="primary" tonal outlined @click="createActa('NuevoActaPartidoOcho')">F8</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red" tonal outlined @click="dialog = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-app>
</template>


<script>
export default {
  data() {
    return {
      isUserLoggedIn: false, // Inicialmente el usuario no ha iniciado sesión
      isSuperAdminLoggedIn: false,
      dialog: false, // Controla la visibilidad del diálogo
      links: [
        'INICIO',
        'ESTADISTICAS JUGADOR',
        'GOLES Y ASISTENCIAS',
        'SANCIONES',
        'DESCONVOCATORIAS',
        'LISTADO ACTAS REGISTRADAS',
        'BLOG INFORMES'
      ],
      golesAsistOptions: ['A', 'B', 'C'], // Opciones adicionales
      linksSuperAdmin: [
        'Usuarios',
        'Equipos'
        //Jugadores, en un futuro 
        //Rivales, en un futuro
        // en definitiva control sobre la base de datos desde aqui por si hay que arreglar algo.
      ],
      Actions: [
        'PERFIL',
        'Plantilla',
        'Rivales',
        'Nuevo Acta',
        'CERRAR SESIÓN',
      ],
      equipoNombre: '', // Para almacenar el nombre del equipo
    };
  },
  computed: {
    getUserLogedIn() {
      return localStorage.getItem('usernameLogedIn') || '';
    },
    initial() {
      if (this.getUserLogedIn) {
        return this.getUserLogedIn.charAt(0).toUpperCase();
      }
      return '';
    }
  },
  mounted() {
    // Verifica si el usuario ha iniciado sesión al cargar la aplicación
    this.checkUserLoggedIn();
    if (this.isUserLoggedIn) {
      this.getEquipoLoguedIn();
    }
  },
  methods: {

    async getEquipoLoguedIn() {

      const usuarioId = localStorage.getItem('userId');
      const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

      const responseCodigo = await fetch(`${API_BASE_URL}/usuarios/${usuarioId}/codigo`);
      if (!responseCodigo.ok) {
        throw new Error('No se pudo obtener el código de usuario');
      }
      const codigoUsuario = await responseCodigo.text(); // Suponiendo que el código de usuario es un texto simple

      if (codigoUsuario == '000000') {
        this.equipoNombre = 'SUPERADMIN';
      } else {
        const response = await fetch(`${API_BASE_URL}/equipos/${codigoUsuario}`);

        if (!response.ok) {
          throw new Error('No se pudo obtener el equipo');
        }
        const equipo = await response.json();

        this.equipoNombre = equipo.nombre;
        localStorage.setItem('categoriaEquipo', equipo.categoria);

      }
    },

    checkUserLoggedIn() {
      // Lógica para verificar si el usuario ha iniciado sesión (por ejemplo, comprobando localStorage)
      const usernameLogedIn = localStorage.getItem('usernameLogedIn');
      this.isUserLoggedIn = !!usernameLogedIn; // Actualiza el estado según si hay un usuario logueado o no

      if (usernameLogedIn == 'SUPERADMIN' || usernameLogedIn == 'superadmin') {
        this.isSuperAdminLoggedIn = true;
      } else {
        this.isSuperAdminLoggedIn = false;
      }
    },

    // Método para mostrar una alerta con el nombre del botón clicado
    showAlert(link) {
      //alert(`Clicaste en el botón: ${link}`);
    },

    navigate(route) {
      // Define la ruta que se desea navegar
      let targetRoute;

      // Utiliza switch-case para asignar la ruta deseada
      switch (route) {
        case 'INICIO':
          targetRoute = { name: 'InicioMenu' };
          break;
        case 'ESTADISTICAS JUGADOR':
          targetRoute = { name: 'StatsJugador' };
          break;
        case 'RANKINGS':
          targetRoute = { name: 'GolesYAsist' };
          break;
        case 'ANALISIS GOLES':
          targetRoute = { name: 'AnalisisGoles' };
          break;
        case 'SANCIONES':
          targetRoute = { name: 'Sanciones' };
          break;
        case 'DESCONVOCATORIAS':
          targetRoute = { name: 'Desconvocatorias' };
          break;
        case 'LISTADO ACTAS REGISTRADAS':
          targetRoute = { name: 'ListadoPartidosRegistrados' };
          break;
        case 'BLOG INFORMES':
          targetRoute = { name: 'Informes' };
          break;
        default:
          targetRoute = { path: route.toLowerCase() };
          break;
      }

      // Verifica si la ruta actual es diferente de la ruta de destino
      if (this.$route.name !== targetRoute.name) {
        // Intenta navegar a la ruta deseada
        this.$router.push(targetRoute).catch(err => {
          // Ignorar el error de navegación duplicada
          if (err.name !== 'NavigationDuplicated') {
            console.error(err); // Log de otros errores si es necesario
          }
        });
      }
    },


    async performAction(action) {
      if (action === 'CERRAR SESIÓN') {
        // Limpiar el localStorage y redirigir al usuario al componente de login
        localStorage.removeItem('usernameLogedIn');
        //actualizamos la variable para desloguear el usuario
        localStorage.setItem('isSomeoneLogedIn', false);

        this.isUserLoggedIn = false;
        this.$router.push('/login'); // Ajusta la ruta según la configuración de tu router
        // En algún lugar de tu código donde quieras forzar un refresh
        window.location.reload();

      } else if (action === 'PERFIL') {
        // Redirige a la página de actualización con el ID del usuario seleccionado
        const id = localStorage.getItem('userId')

        if (id) {
          try {
            await this.$router.push({ name: 'ObservarUsuario', params: { id } });
          } catch (error) {
            if (error.name !== 'NavigationDuplicated') {
              console.error(error); // Solo maneja otros errores
            }
          }

        } else {
          // Maneja el caso en el que el ID es nulo, indefinido o vacío

          alert('El ID del usuario no está disponible.');
          performAction('CERRAR SESIÓN');
        }
      } else if (action === 'Plantilla') {
        try {
          await this.$router.push({ name: action });
        } catch (error) {
          if (error.name !== 'NavigationDuplicated') {
            console.error(error); // Solo maneja otros errores
          }
        }
      } else if (action === 'Rivales') {
        try {
          await this.$router.push({ name: action });
        } catch (error) {
          if (error.name !== 'NavigationDuplicated') {
            console.error(error); // Solo maneja otros errores
          }
        }
      } else if (action === 'Nuevo Acta') {
        this.dialog = true;
      }
    },

    createActa(routeName) {
      this.dialog = false;
      this.$router.push({ name: routeName });
    }

  },
};
</script>

<style scoped>
@media (max-width: 600px) {
  .v-btn {
    font-size: 0.8rem;
    /* Reduce el tamaño de la fuente en pantallas pequeñas */
  }
}

.app-name {
  font-size: 1.5rem;
  /* Aumenta el tamaño del texto */
  font-weight: bold;
  /* Negrita */
  color: white;
  /* Color del texto */
  margin-left: 20px;
  /* Espaciado a la izquierda */
  text-transform: uppercase;
  /* Letras en mayúsculas */
  letter-spacing: 1px;
  /* Espaciado entre letras */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  /* Sombra para darle profundidad */

}
</style>
