<template>
  <v-card class="mx-auto" max-width="400">
    <v-card-title class="headline">
      Actualizar Equipo
    </v-card-title>

    <v-card-text>
      <equipo-form ref="EquipoForm" :initialData="equipoData"></equipo-form>
    </v-card-text>

    <v-card-actions>
      <v-btn @click="actualizarEquipo" color="primary">
        Actualizar
      </v-btn>
      <v-btn @click="volverAEquipos" color="primary">
        Volver
      </v-btn>
    </v-card-actions>

    <v-alert
      v-if="alertMessage"
      dense
      :type="alertType"
    >
      {{ alertMessage }}
    </v-alert>
  </v-card>
</template>

<script>
import EquipoForm from '../../components/EquipoComponents/EquipoForm.vue';

export default {
  components: {
    EquipoForm,
  },
  data() {
    return {
      alertMessage: null,
      alertType: null,
      equipoData: null,
    };
  },
  methods: {
    async cargarDatosEquipo(codigo) {
      try {
        const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
        const response = await fetch(`${API_BASE_URL}/equipos/${codigo}`);
        if (!response.ok) {
          throw new Error('Error al obtener los datos del equipo');
        }
        const equipoData = await response.json();
        this.equipoData = equipoData;
      } catch (error) {
        console.error('Error al cargar los datos del equipo:', error);
        this.setAlert('Error al cargar los datos del equipo, pulse en volver e intentelo de nuevo.', 'error');
      }
    },
    async actualizarEquipo() {
      const equipoFormRef = this.$refs.EquipoForm;
      const equipoFormData = equipoFormRef.getFormData();
      equipoFormRef.activateRules();

      if (!equipoFormData.nombre || !equipoFormData.categoria || !equipoFormData.club) {
        this.setAlert('Hay campos incompletos. Por favor, rellene todos los campos.', 'error');
        return;
      }

      try {
        const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
        const response = await fetch(`${API_BASE_URL}/equipos/${this.equipoData.codigo}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            nombre: equipoFormData.nombre,
            categoria: equipoFormData.categoria,
            club: equipoFormData.club,
          }),
        });

        if (response.ok) {
          this.setAlert('El equipo se ha actualizado correctamente', 'success');
        } else {
          let errorMessage = 'Error al actualizar el equipo.';

          try {
            const responseText = await response.text();
            errorMessage = responseText || errorMessage;
          } catch (textError) {
            console.error('Error al obtener el texto de la respuesta:', textError);
          }

          this.setAlert(errorMessage, 'error');
        }

      } catch (error) {
        console.error('Error al realizar la solicitud:', error);
        this.setAlert('Ocurrió un error. Por favor, inténtelo de nuevo.', 'error');
      }
    },
    volverAEquipos() {
      // Aquí se redirige a la página de equipos
      this.$router.push('/equipos');
    },
    setAlert(message, type) {
      this.alertMessage = message;
      this.alertType = type;
    },
  },
  created() {
    const equipoCodigo = this.$route.params.codigo;
    this.cargarDatosEquipo(equipoCodigo);
  },
};
</script>

<style scoped>
/* Estilos específicos para el componente */
</style>
