<template>
  <div>
    <v-text-field v-model="formData.nombre" label="Nombre" :disabled="disableFields"></v-text-field>
    <v-text-field v-model="formData.apellido" label="Apellido" :disabled="disableFields"></v-text-field>
    <v-text-field v-model="formData.username" label="Username" :disabled="disableFields"></v-text-field>
    <v-text-field v-model="formData.password" label="Password"       
      :disabled="disableFields || disablePasswordFields"
      type="password">
    </v-text-field>
    <v-text-field v-model="formData.confirmPassword" label="Confirmar Password"       
      :disabled="disableFields || disablePasswordFields"
      type="password">
    </v-text-field>
    <v-select
      v-model="formData.codigo"
      :items="equipos"
      item-text="nombreCompuesto"
      item-value="codigo"
      label="Equipo"
      :disabled="disableFields"
      @change="onEquipoChange"
    ></v-select>
    <v-text-field
      v-if="showCodigoManual"
      v-model="formData.codigoManual"
      label="Código Manual"
      :disabled="disableFields">
    </v-text-field>
  </div>
</template>

<script>
export default {
  name: 'UsuarioForm',
  props: {
    initialData: {
      type: Object,
      default: null,
    },
    disablePasswordFields: {
      type: Boolean,
      default: false,
    },
    disableFields: {
      type: Boolean,
      default: false,
    },
    equipos: [], // Propiedad para pasar la lista de equipos al componente
  },
  data() {
    return {
      formData: {
        nombre: '',
        apellido: '',
        username: '',
        password: '',
        confirmPassword: '',
        codigo: '', // Variable para almacenar el código del equipo seleccionado
        codigoManual: ''
      },
      // Agrega una propiedad para controlar la visibilidad de las reglas
      showRules: false,
      showCodigoManual: false,
    };
  },
  computed: {
    nombreRules() {
      return [
        (v) => (!!v || this.showRules) || 'El nombre es obligatorio',
        // Otras reglas de validación para el nombre
      ];
    },
    apellidoRules() {
      return [
        (v) => (!!v || this.showRules) || 'El apellido es obligatorio',
        // Otras reglas de validación para el apellido
      ];
    },
    usernameRules() {
      return [
        (v) => (!!v || this.showRules) || 'El nombre de usuario es obligatorio',
        // Otras reglas de validación para el nombre de usuario
      ];
    },
    passwordRules() {
      return [
        (v) => (!!v || this.showRules) || 'La contraseña es obligatoria',
        // Otras reglas de validación para la contraseña
      ];
    },
    confirmPasswordRules() {
      return [
        (v) => (!!v || this.showRules) || 'Confirme la contraseña',
        (v) => v === this.formData.password || 'Las contraseñas no coinciden',
        // Otras reglas de validación para la confirmación de la contraseña
      ];
    },
  },
  watch: {
    initialData: {
      immediate: true,
      handler(newData) {
        if (newData) {
          this.formData = {
            nombre: newData.nombre,
            apellido: newData.apellido,
            username: newData.username,
            password: newData.password,
            confirmPassword: newData.password,
            codigo: Number(newData.codigo),
          };
        }
      },
      activateRules() {
      // Aquí puedes agregar lógica para activar reglas de validación si es necesario
     }
    }
  },
  methods: {

    onEquipoChange() {
      this.showCodigoManual = !!this.formData.codigo;
    },

    // Método para activar manualmente las reglas
    activateRules() {
      this.showRules = true;
    },
    // Método para obtener los datos del formulario
    getFormData() {
      return { ...this.formData };
    },
    activateRules() {
      // Aquí puedes agregar lógica para activar reglas de validación si es necesario
    },
    resetForm() {
      this.formData.nombre = '';
      this.formData.apellido = '';
      this.formData.username = '';
      this.formData.password = '';
      this.formData.confirmPassword = '';
      this.formData.codigo = '';
    }
    // Otros métodos del componente según sea necesario
  }
}
</script>

<style scoped>
/* Estilos específicos para el componente */
</style>
