<template>
  <v-container>
    <v-alert ref="alert" :value="true" v-model="alert.show" :type="alert.type">
      {{ alert.message }}
    </v-alert>
    <v-card-title class="headline">
      Blog de Informes
      <v-spacer></v-spacer>
      <v-btn @click="redirigirACrearInforme" icon>
        <v-icon>fas fa-plus</v-icon>
      </v-btn>
    </v-card-title>
    <v-data-table :headers="headers" :items="informes" class="elevation-1">
      <template v-slot:item="{ item }">
        <tr>
          <td>
            <v-btn @click="redirigirAActualizar(item.id)" icon>
              <v-icon>fas fa-pencil-alt</v-icon>
            </v-btn>
            <v-btn @click="redirigirAObservar(item.id)" icon>
              <v-icon>fas fa-eye</v-icon>
            </v-btn>
            <v-btn @click="mostrarConfirmacionEliminar(item.id)" icon>
              <v-icon>fas fa-trash-alt</v-icon>
            </v-btn>
          </td>
          <td>{{ item.nombre }}</td>
          <td>{{ item.tipo }}</td>
          <td>{{ item.temporada }}</td>
          <td>{{ item.jornada }}</td>
        </tr>
      </template>
    </v-data-table>

    <confirmacion-eliminar-informe :showDialog="confirmacionEliminar" :closeDialog="cerrarConfirmacionEliminar"
      :confirmarAccionUsuario="eliminarInforme" :mensajeConfirmacion="mensajeConfirmacion" />
  </v-container>
</template>

<script>
import ConfirmacionEliminarInforme from '../../../components/ConfirmacionEliminar.vue';

export default {
  components: {
    ConfirmacionEliminarInforme,
  },
  data() {
    return {
      headers: [
        { text: 'Acciones', value: 'acciones', sortable: false },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Tipo', value: 'tipo' },
        { text: 'Temporada', value: 'temporada' },
        { text: 'Jornada', value: 'jornada' },
      ],
      codigoUsuarioFiltro: '',
      informes: [],
      confirmacionEliminar: false,
      informeAEliminarId: null,
      mensajeConfirmacion: '',
      alert: {
        show: false,
        type: 'success',
        message: '',
      },
    };
  },
  created() {
    this.inicializar();
  },
  methods: {
    async inicializar() {
      try {
        await this.getCodigoUsuario(); // Esperar a que getCodigoUsuario termine
        this.cargarInformes(); // Ejecutar cargarInformes después de obtener el código de usuario
      } catch (error) {
        console.error('Error al inicializar:', error);
        this.error('Error al inicializar la carga de informes.');
      }
    },
    cargarInformes() {
      const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
      // Realiza la petición GET a la API de informes
      fetch(`${API_BASE_URL}/informes/codigo/${this.codigoUsuarioFiltro}`)
        .then(response => response.json())
        .then(data => {
          this.informes = data;
        })
        .catch(error => {
          console.error('Error al cargar informes:', error);
          this.error('Error al cargar los informes.');
        });
    },
    async getCodigoUsuario() {
      const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

      const usuarioId = localStorage.getItem('userId');

      const responseCodigo = await fetch(`${API_BASE_URL}/usuarios/${usuarioId}/codigo`);
      if (!responseCodigo.ok) {
        throw new Error('No se pudo obtener el código de usuario');
      }
      const codigoUsuario = await responseCodigo.text(); // Suponiendo que el código de usuario es un texto simple

      this.codigoUsuarioFiltro = codigoUsuario;
    },
    redirigirACrearInforme() {
      this.$router.push({ name: 'CrearInforme' });
    },
    redirigirAActualizar(id) {
      this.$router.push({ name: 'ActualizarInforme', params: { id } });
    },
    redirigirAObservar(id) {
      this.$router.push({ name: 'ObservarInforme', params: { id } });
    },
    mostrarConfirmacionEliminar(id) {
      this.informeAEliminarId = id;
      this.mensajeConfirmacion = `¿Estás seguro de que deseas eliminar el informe con ID ${id}?`;
      this.confirmacionEliminar = true;
    },
    cerrarConfirmacionEliminar() {
      this.informeAEliminarId = null;
      this.confirmacionEliminar = false;
    },
    eliminarInforme() {
      const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
      fetch(`${API_BASE_URL}/informes/${this.informeAEliminarId}`, {
        method: 'DELETE',
      })
        .then(response => {
          if (response.ok) {
            this.success(`Informe  eliminado correctamente.`);
            this.cargarInformes(); // Recarga la lista de informes
          } else {
            this.error(`Error al eliminar el informe seleccionado.`);
          }
        })
        .catch(error => {
          console.error('Error al realizar la solicitud DELETE:', error);
          this.error('Error al eliminar el informe. Por favor, intenta nuevamente.');
        })
        .finally(() => {
          this.cerrarConfirmacionEliminar();
        });
    },
    success(message) {
      this.alert.show = true;
      this.alert.type = 'success';
      this.alert.message = message;
    },
    error(message) {
      this.alert.show = true;
      this.alert.type = 'error';
      this.alert.message = message;
    },
  },
};
</script>

<style scoped>
/* Estilos específicos del componente */
</style>