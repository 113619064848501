<template>
    <v-card class="mx-auto" max-width="600">
      <v-card-title class="headline">
        Bienvenido a la app web de Gestión de plantillas de fútbol base.
      </v-card-title>
  
      <v-card-text>
        <p>
          Esta es una app web simple con las funcionalidades básicas para un entrenador de categorias inferiores.
          Puedes interactuar con las siguientes funcionalidades:
        </p>
  
        <ul>
          <li>Iniciar sesión</li>
          <li>Registrarte como entrenador</li>
          <li>Modificar tu perfil</li>
          <li>Eliminar tu usuario</li>
          <li>Crear tu plantillas y gestionarlas.</li>
          <li>Observar datos sobre tus jugadores.</li>
          <li>Realizar seguimiento de partidos y de entrenos.</li>
          <li>Y demás funcionalidades que están en camino.</li>
        </ul>
      </v-card-text>
    </v-card>
  </template>
  
  <script>
  export default {
    // Puedes agregar lógica adicional aquí si es necesario
  };
  </script>
  
  <style scoped>
  /* Puedes agregar estilos específicos para tu componente aquí */
  </style>
  